import 'whatwg-fetch'
import * as Sentry from '@sentry/browser'

export const REQUEST_UPDATE_REMINDER_EMAIL = 'REQUEST_UPDATE_REMINDER_EMAIL'
export const RECEIVE_UPDATE_REMINDER_EMAIL = 'RECEIVE_UPDATE_REMINDER_EMAIL'

export function gather (actions, apiPromise) {
  return dispatch => {
    dispatch(request(actions.request, ''))
    return apiPromise
      .then(json => {
        return dispatch(receive(actions.receive, '', json))
      })
      .catch(err => {
        console.error(err)
        if (!err.sentToSentry) {
          // If the error wasn't captured upstream, capture it here.
          /*
            This is necessary because _fetch will capture errors from the server, but not exceptions that
            occur here in the frontend.  The SERVER_ERROR action still needs to be dispatched for
            actual errors from the server, but exceptions that cause a SERVER_ERROR action that did not
            come from the server must still be captured
          */
          Sentry.captureException(err)
        }
      })
  }
}

export function request (type, path, body = {}) {
  return {
    type,
    path,
    body
  }
}

export function receive (type, path, response) {
  return {
    type,
    path,
    response,
    receivedAt: (new Date()).toJSON().toString()
  }
}