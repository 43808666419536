import React, { Component } from 'react'

import { cog } from '../api/cognition'
import Cookies from 'js-cookie'
import SafeHTML from '../components/SafeHTML.jsx'


const cookieName = 'hideSafetyMessageId'
const colors = [
  '#ffb3ba', // red
  '#ffdfba', // orange
  '#ffffba', // yellow
  '#baffc9', // green
  '#bae1ff'// blue
]
class GlobalMessage extends Component {
  constructor (props) {
    super(props)
    const hideId = parseInt(Cookies.get(cookieName), 10)
    this.state = {
      id: null,
      html: '',
      hideId
    }
    this.dismissMessage = this.dismissMessage.bind(this)
  }
  componentDidMount () {
    // Get the current message
    cog.getGlobalMessages(true).then(message => {
      if (message && message[0]) {
        this.setState({
          id: message[0].id,
          html: message[0].text
        })
      }
    })
  }
  dismissMessage () {
    const {id} = this.state
    Cookies.set(cookieName, id, {sameSite:'Strict'})
    this.setState({hideId: id})
  }
  render () {
    const {html, id, hideId} = this.state
    if (html && hideId !== id) {
      return (
        <div
          className='globalMessage'
          style={{
            width: '100%',
            padding: '16px 32px 0 32px',
            margin: '0 auto',
            maxWidth: '1140px'
          }}
        >
          <div style={{
            backgroundColor: colors[id % colors.length],
            padding: '16px',
            // Extra room on the right for the 'x'
            paddingRight: '32px',
            borderRadius: '4px',
            position: 'relative'
          }}>
            {/* Note: This html is only set by admins */}
            <SafeHTML html={html}/>
            <i
              style={{
                position: 'absolute',
                top: '0',
                right: '0',
                padding: '16px',
                cursor: 'pointer'
              }}
              onClick={this.dismissMessage}
              className={'fa fa-times'} />
          </div>
        </div>)
    } else {
      return null
    }
  }
}

export default GlobalMessage
