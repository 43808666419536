export function emailDomain (email) {
  if (!email) {
    return ''
  }
  const index = email.indexOf('@')
  if (index === -1) {
    return ''
  }
  return email.substring(index + 1).toLowerCase()
}
