import React from 'react'
import PropTypes from 'prop-types'
import Spinner from './Spinner.jsx'
import classname from 'classnames'

export default function ButtonWithSpinner (props) {
  const {percentage, type, className, loading, title, onClick} = props
  // Note, this button only invokes the onClick callback when it is not in the loading state
  return loading
    ? (
      <button type={type || 'button'} disabled className={classname('btn btn-primary disabled', className)}>
        {
          percentage !== undefined
            ? <Spinner percentage={percentage} />
            : <i className='fa fa-circle-notch fa-spin' />
        }
      </button>
    )
    : (
      <button type={type || 'button'} className={classname('btn btn-primary', className)} onClick={onClick}>{title}</button>
    )
}
ButtonWithSpinner.propTypes = {
  loading: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  // 0 - 100
  percentage: PropTypes.number,
  // Optional override for the button's 'type'
  type: PropTypes.string,
  className: PropTypes.string

}
