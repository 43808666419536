import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import classNames from 'classnames'
import _flattenDeep from 'lodash.flattendeep'
import _get from 'lodash.get'
import {
  Link
} from 'react-router-dom'

import { cog } from '../../api/cognition'
import {ISO_DATE_FORMAT} from '../../constants.js'




class WeeklyCompletions extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedUserAzureId: null,
      drilldown: [],
      drilldownLoading: {},
      weeklyCompletedReport: {}
    }
    this.renderQuickActions = this.renderQuickActions.bind(this)
    this.renderRow = this.renderRow.bind(this)
  }
  componentDidMount () {
    this.getWeeklyCompleted()
  }
  renderQuickActions (azureId, depth) {
    return <tr key={'quickActions'}>
      <td colSpan={3} style={this.getRecursiveStyle(depth)}>
        <Link to={`/observation/office?quickActionAzureId=${azureId}`} className={'btn btn-primary margin-left-5'}>Office</Link>
        <Link to={`/observation/field?quickActionAzureId=${azureId}`} className={'btn btn-primary margin-left-5'}>Field</Link>
      </td>
    </tr>
  }
  putPromiseInState(promise, key){
    this.setState({[key]:{loading:true,error:null,data:null}})
    promise.then(data => {
      this.setState({[key]:{loading:false,error:null,data}})
    }).catch(_=>{
      this.setState({[key]:{loading:false,error:'Could not load data',data:null}})
    })
  }

  getWeeklyCompleted () {
    const { user } = this.props
    this.startDate = moment().startOf('isoWeek').format(ISO_DATE_FORMAT)
    this.endDate = moment().format(ISO_DATE_FORMAT)
    this.putPromiseInState(cog.getWeeklyLeaderCompleted({
      azureId: user.profile.azure_id,
      startDate: this.startDate,
      endDate: this.endDate
    }), 'weeklyCompletedReport')
  }
  getRecursiveStyle (depth) {
    const style = {}
    if (depth > 0) {
      style.borderLeft = `${30 * depth}px solid #ccc`
    }
    return style
  }
  renderRow (user, depth) {
    const { user: { profile: { id } } } = this.props
    if (!depth) {
      depth = 0
    }
    const completed = user.count >= (user.weekly_obs_expectation || 1) 
    const completedClass = classNames({
      'fa': true,
      'fa-color-green': completed,
      'fa-color-red': !completed,
      'fa-check-circle': completed,
      'fa-times-circle': !completed
    })
    const wasObserved = user.observed_count > 0
    const observedClass = classNames({
      'fa': true,
      'fa-color-green': wasObserved,
      'fa-color-blue': !wasObserved,
      'fa-check-circle': wasObserved,
      'fa-dot-circle': !wasObserved
    })
    const isCurrentUser = user.id === id
    const rowClass = classNames({
      'current-user': isCurrentUser
    })
    const drilldownInfo = this.state.drilldown.find(u => u.id === user.id)
    const primaryRow = (
      <tr key={user.id} className={rowClass}>
        <td style={this.getRecursiveStyle(depth)}>
          {isCurrentUser || (drilldownInfo && !drilldownInfo.children.length) || !(user.hierarchy && user.hierarchy.is_manager)
            ? null
            : (
              <span onClick={() => {
                // Query for user's hierarchy drilldown

                if (this.state.drilldownLoading[user.id]) {
                  // Abort, data is already loading for this user
                  return
                }
                const preExistingIndex = this.state.drilldown.findIndex(u => u.id === user.id)
                if (preExistingIndex !== -1) {
                  // User already found in drilldown, toggle, but don't reload data
                  const drilldownClone = this.state.drilldown.slice(0)
                  drilldownClone[preExistingIndex].expanded = !drilldownClone[preExistingIndex].expanded
                  this.setState({
                    drilldown: drilldownClone
                  })
                  return
                }
                // set loading
                this.setState({
                  drilldownLoading: Object.assign({}, this.state.drilldownLoading, { [user.id]: true })
                })
                cog.getWeeklyLeaderCompleted({
                  azureId: user.azure_id,
                  startDate: this.startDate,
                  endDate: this.endDate
                }).then(users => {
                  if (this.state.drilldown.find(u => u.id === user.id)) {
                    // prevent duplicates in the drilldown array

                  } else {
                    this.setState({
                      drilldownLoading: Object.assign({}, this.state.drilldownLoading, { [user.id]: false }),
                      drilldown: this.state.drilldown.concat({
                        id: user.id,
                        // Remove the first user:
                        // getWeeklyLeaderCompleted returns the
                        // user with azureId as the first user
                        children: users.filter(u => u.id !== user.id),
                        expanded: true
                      })
                    })
                  }
                }).catch(() => {
                  this.setState({
                    drilldownLoading: Object.assign({}, this.state.drilldownLoading, { [user.id]: false }),
                    drilldown: this.state.drilldown.concat({
                      id: user.id,
                      children: [],
                      expanded: true
                    })
                  })
                })
              }}>
                {
                  this.state.drilldownLoading[user.id]
                    ? <i className='fa fa-circle-notch fa-spin fa-fw' />
                    : drilldownInfo && drilldownInfo.expanded
                      ? <i className='fa fa-chevron-down' aria-hidden='true' />
                      : <i className='fa fa-chevron-right' aria-hidden='true' />
                }
                &nbsp;
              </span>
            )
          }
          {isCurrentUser
            ? <strong>{user.display_name}</strong>
            : (<span className='fake-link' onClick={() => {
              if (this.state.selectedUserAzureId === user.azure_id) {
                // clear quick actions:
                this.setState({ selectedUserAzureId: null })
              } else {
                // Set this user as the selected user
                // to show quick options for selected user
                this.setState({
                  selectedUserAzureId: user.azure_id
                })
              }
            }}>{user.display_name}</span>
            )}
        </td>
        <td className={'text-center'}>
          {user.compliance_required ? <i className={completedClass} aria-hidden='true' /> : '--'}
        </td>
        <td className={'text-center'}>
          <i className={observedClass} aria-hidden='true' />
        </td>
      </tr>
    )
    let rows = [primaryRow]
    if (this.state.selectedUserAzureId && this.state.selectedUserAzureId === user.azure_id) {
      rows = rows.concat(this.renderQuickActions(this.state.selectedUserAzureId, depth))
    }
    for (const userDrilldown of this.state.drilldown) {
      if (user.id === userDrilldown.id && userDrilldown.expanded) {
        // Prevent infinite recursion in the case that a user has themself as child
        const childrenRows = _flattenDeep(userDrilldown.children.map(child => {
          if (child.id !== user.id) {
            return this.renderRow(child, depth + 1)
          } else {
            return null
          }
        }))
        rows = rows.concat(childrenRows)
      }
    }
    return rows
  }

  weeklyLeaderCompletionRows (reportItems) {
    let rows = reportItems.map(user => this.renderRow(user, false))
    if(reportItems.length === 101){
      rows.push(<tr key='limit'><td colSpan={3} style={{textAlign:'center'}}><a className='btn btn-primary' href='/reports/not-observed'>Load More</a></td></tr>)
    }
    return rows
  }

  render () {
    const { user } = this.props
    const {weeklyCompletedReport} = this.state
    // Current user to the top of the array
    const users = !weeklyCompletedReport.data ? [] : weeklyCompletedReport.data.reduce((acc, element) => {
      const isCurrentUser = element.id === _get(user, 'profile.id')
      if (isCurrentUser) {
        acc.unshift(element)
      } else {
        acc.push(element)
      }
      return acc
    }, [])
    if(!users.length && !weeklyCompletedReport.loading){
      return null
    }
    const loadingDisplay = (
      <tr>
        <td className='text-center' colSpan={'100%'}>
          <i className='fa fa-circle-notch fa-spin fa-fw' />
          <span className='sr-only'>Loading...</span>
        </td>
      </tr>
    )
    return (
      <div className='panel panel-default weekly-compliance'>
        <div className='panel-heading'>
          Weekly Compliance
        </div>
        <div className='table-responsive'>
          <table className='table'>
            <thead>
              <tr>
                <th>Employee</th>
                <th className={'text-center'}>Compliant This Week</th>
                <th className={'text-center'}>Observed This Quarter</th>
              </tr>
            </thead>
            <tbody>
              {weeklyCompletedReport.loading ? loadingDisplay : this.weeklyLeaderCompletionRows(users)}
            </tbody>
          </table>
        </div>
      </div>
    )
  }
}

WeeklyCompletions.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object.isRequired,
}

function mapStateToProps (state) {
  const { user } = state
  return {
    user,
  }
}

export default connect(mapStateToProps)(WeeklyCompletions)
