import React from 'react'
import PropTypes from 'prop-types'

const size = 40
export default function LoadingOverlay (props) {
  return props.isVisible ? (
    <div className='overlay-background' style={{
      position: props.isFixedPosition ? 'fixed' : 'absolute',
    }}>
      <div style={{
        width: `${size}px`,
        height: `${size}px`,
        position: 'absolute',
        top: props.positionTop || '50%',
        left: '50%',
        margin: `-${size / 2}px 0 0 -${size / 2}px`
      }}>
        {props.proportion !== undefined ? (<span>
          <i className='fa fa-circle-notch fa-spin fa-2x' />
          &nbsp;&nbsp;<span style={{fontSize: '2em', fontWeight: 'bold'}}>{Math.round(100 * props.proportion)}%</span>
        </span>)
          : <i className='fa fa-circle-notch fa-spin fa-2x' />}
      </div>
    </div>) : null
}

LoadingOverlay.propTypes = {
  isVisible: PropTypes.bool,
  // Allows for moving the top position of the loading spinner,
  // useful for very tall components where the center of the component might be
  // out of view
  positionTop: PropTypes.string,
  // Allows the spinner to be always centered when the loading overlay is so tall
  // that it covers more than the whole screen.  Choosing this option is up to the developer's
  // discretion.  See EditSQS for an example of when it should be fixed
  isFixedPosition: PropTypes.bool,
  // 0.0 - 1.0 will show a percentage near the loader
  // undefined is allowed to hide
  proportion: PropTypes.number
}
