import React from 'react'
import * as Sentry from '@sentry/browser'

export default function fourOhFour () {
  /*
  Report 404s to sentry.
  This is highly useful in the event that a path on the site
  leads users to a page that doesn't exist.  This message in sentry
  will alert the developer of this possibility when it occurs
  */
  Sentry.captureMessage(`404 at ${location.pathname}`)
  return <div style={{
    width: '100%',
    textAlign: 'center',
    margin: '64px auto'
  }}>
    <h1>404</h1>
    <div>Page Not Found</div>
  </div>
}
