import React from 'react'
import snarkdown from 'snarkdown'
import xss from 'xss'

export default function SafeHTML(props) {
   const {html, markdown} = props
   if(markdown){
      // Sanitize AFTER converting markdown to HTML
      return <div dangerouslySetInnerHTML={{__html:xss(snarkdown(markdown))}}/>
   }else {
      return <div dangerouslySetInnerHTML={{__html:xss(html)}}/>

   }
}