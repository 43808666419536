import { stringify } from 'query-string'
import * as Sentry from '@sentry/browser'

import { addRequestAuthOptions } from '../auth/auth'
import { API_HOST } from '../constants.js'
import * as AuthToast from '../containers/AuthToast.jsx'
import OAuth from '../auth/oauth.js'

class Cognition {
  constructor (version = 'v1') {
    this.baseUri = API_HOST[version]
  }
  // Handles building request and fetching
  // --
  // Use _fetch() for any request where an accessToken is mandatory
  // _fetchRaw() will add the accessToken if it exists (using addRequestAuthOptions),
  _fetchRaw(uri, options = {}){
      return fetch(uri, addRequestAuthOptions(options))
      .then(this.checkResponseJSON)
      .catch(error => {
        try {
          if (error.status === 401) {
              // Prompt the user to log back in manually
              AuthToast.show({allowReanimate:true});
          } else {
            console.error(error)
            Sentry.captureException(error)
            error.sentToSentry = true
          }
          // Rereject the error so that callers of _fetch can trigger specific functions on error
          return Promise.reject(error)
        } catch (e) {
          // Log error that occurred within this callback
          console.error(e)
          // Reject original error
          return Promise.reject(error)
        }
      })

  }

  // Ensure oauth token is valid before making a request
  // --
  // Use _fetchRaw() for making requests where accessToken is not mandatory
  // --
  // If the accessToken has expired, _fetch() will wait to make the request until the token is renewed
  // which results in a more seamless UX.  And in the event that it cannot be renewed and the user
  // must manually log back in, a 401 will show the AuthToast, prompting the user to do so (this occurs)
  // within _fetchRaw
  _fetch (uri, options = {}) {
    return OAuth.refreshTokenIfNeeded()
      .catch(() => {
        // errors should be caught silently, allow a 401 to occur (or it wont 401 if the request doesn't require auth)
        // due to a fetch with an old accessToken if refreshTokenIfNeeded fails
        console.log('cognition._fetch: unable to silently renew token before fetching', uri)
      })
      .then(() => this._fetchRaw(uri, options))
  }

  checkResponseJSON (response) {
    if (!response.ok) {
      return response.text().then(errString => {
        let err
        try{
          // errString may be stringified json
          const errJson = JSON.parse(errString)
          const {message, ...restErrJson} = errJson
          err = new Error(message)
          err = Object.assign(err, restErrJson)
        }catch(e){
          // If it can't be parsed then it's just a string
          err = new Error(errString)
        }
        err.ok = response.ok
        err.status = response.status
        err.statusText = response.statusText
        err.url = response.url
        throw err
      })
    } else {
      return response.text().then(text => {
        try {
          return text ? JSON.parse(text) : undefined
        } catch (e) {
          // Text may be non JSON, such as '200 OK', return it as-is
          return text
        }
      })
    }
  }
  get (url, params) {
    const queryString = params ? `?${stringify(params)}` : ''
    return this._fetch(`${this.baseUri}${url}${queryString}`)
  }
  post (url, body) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(body)
    }
    return this._fetch(`${this.baseUri}${url}`, options)
  }
  delete (url, params) {
    const options = {
      method: 'DELETE'
    }
    const queryString = params ? `?${stringify(params)}` : ''
    return this._fetch(`${this.baseUri}${url}${queryString}`, options)
  }

  // region users
  getUser (id) {
    return this._fetch(`${this.baseUri}/users/${id || 'me'}`)
  }

  getUsers ({page, name, role}) {
    let url = `${this.baseUri}/users/all?pageNum=${page}`
    if (name) {
      url += `&name=${name}`
    }
    if (role) {
      url += `&role=${role}`
    }
    return this._fetch(url)
  }
  setUserRole (id, role) {
    const userData = {
      role
    }
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(userData)
    }
    return this._fetch(`${this.baseUri}/users/${id}/update`, options)
  }

  userPhotoUri (id, size) {
    return `${this.baseUri}/users/${id}/photo/${size}`
  }
  getQueryStringFromFilter (filter) {
    if (typeof filter !== 'string') {
      return null
    }
    // filter could be a name or and employee_id, extract ID if it exists
    // A DTE uID may be referred to as 'u12345' but is stored as '00112345' in the db
    const employeeIDMatch = filter.match(/^\D?(\d+)/)
    const queryString = employeeIDMatch
      // If a match was found search by uID with the numbers of the id only, exclude any letters
      ? `?employee_id=${employeeIDMatch[1]}`
      // otherwise assume 'filter' is a name
      : `?name=${filter}`

    return queryString
  }
  safetyTeamSearch(filter){
    const queryString = this.getQueryStringFromFilter(filter)
    // Query string is optional for this search
    return this._fetch(`${this.baseUri}/safetyTeam/search${queryString ? queryString : ''}`)
  }

  userSearch (filter) {
    const queryString = this.getQueryStringFromFilter(filter)
    if (queryString) {
      return this._fetch(`${this.baseUri}/users/directory/search/${queryString}`)
    } else {
      return Promise.reject(new Error(`Query string is invalid`))
    }
  }

  leaderSearch (filter) {
    const queryString = this.getQueryStringFromFilter(filter)
    if (queryString) {
      return this._fetch(`${this.baseUri}/users/leaders/search/${queryString}`)
    } else {
      return Promise.reject(new Error(`Query string is invalid`))
    }
  }

  updateReminderEmail (body) {
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify(body)
    }
    return this._fetch(`${this.baseUri}/users/me`, options)
  }

  getUserProfile (azureId) {
    return this._fetch(`${this.baseUri}/users/${azureId}/profile`)
  }
  // endregion

  // region questionnaires
  getQuestionnaires () {
    return this._fetch(`${this.baseUri}/questionnaires`)
  }

  getQuestionnaireBySlug (slug) {
    return this._fetch(`${this.baseUri}/questionnaires/${slug}`)
  }
  // endregion

  // region global_message
  getGlobalMessages (onlyDisplayed) {
    return this._fetch(`${this.baseUri}/global_message?onlyDisplayed=${onlyDisplayed}`)
  }
  hideGlobalMessages () {
    const options = {
      method: 'DELETE'
    }
    return this._fetch(`${this.baseUri}/global_message`, options)
  }
  addGlobalMessage (message) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({message})
    }
    return this._fetch(`${this.baseUri}/global_message`, options)
  }
  // endregion

  // region organizations
  getOrganizations () {
    return this._fetch(`${this.baseUri}/organizations`)
  }

  getOrganization (id) {
    return this._fetch(`${this.baseUri}/organizations/${id}`)
  }
  // endregion

  // region locations
  getLocations () {
    return this._fetch(`${this.baseUri}/locations`)
  }

  getLocation (id) {
    return this._fetch(`${this.baseUri}/locations/${id}`)
  }
  // endregion

  // region categories
  getCategories () {
    return this._fetch(`${this.baseUri}/categories`)
  }
  // endregion

  // region companies
  companySearch (filter) {
    return this._fetch(`${this.baseUri}/companies/search?filter=${filter}`)
  }
  getCompanies () {
    return this._fetch(`${this.baseUri}/companies`)
  }
  // endregion

  // region responses
  getResponses (params) {
    return this._fetch(`${this.baseUri}/responses?${stringify(params)}`)
  }

  getResponsesById (id) {
    return this._fetch(`${this.baseUri}/responses/${id}`)
  }

  deleteResponseById (id) {
    const options = {
      method: 'DELETE'
    }
    return this._fetch(`${this.baseUri}/responses/${id}`, options)
  }

  postResponse (body, {azure_id, nonce, resumeId}) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({response: body, azure_id, nonce, resumeId})
    }
    return this._fetch(`${this.baseUri}/responses`, options)
  }

  editResponses (body, rating){
    const options = {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({response: body, rating})
    }
    return this._fetch(`${this.baseUri}/editResponses`, options)
  }
  // endregion

  // region reports
  getWeeklyLeaderCompleted (params) {
    return this._fetch(`${this.baseUri}/reports/responses/completed?${stringify(params)}`)
  }

  getRollUpObserved (params) {
    return this._fetch(`${this.baseUri}/reports/responses/observed?${stringify(params)}`)
  }

  getCompliance (params) {
    return this._fetch(`${this.baseUri}/reports/responses/compliance?${stringify(params)}`)
  }

  getQuality (params) {
    return this._fetch(`${this.baseUri}/reports/responses/quality?${stringify(params)}`)
  }

  getLifeCritical (params) {
    return this._fetch(`${this.baseUri}/reports/responses/lifecritical?${stringify(params)}`)
  }

  getLeaderCompliance (params) {
    return this._fetch(`${this.baseUri}/reports/responses/export/leader-compliance?${stringify(params)}`)
  }

  getLeaderHierarchyExclusions (params) {
    return this._fetch(`${this.baseUri}/reports/exclusions/count?${stringify(params)}`)
  }

  getEPOSingleLineExport (params) {
    return this._fetch(`${this.baseUri}/reports/responses/export/epo/single-line?${stringify(params)}`)
  }

  getEPOMultiLineExport (params) {
    return this._fetch(`${this.baseUri}/reports/responses/export/epo/multi-line?${stringify(params)}`)
  }

  getSAPDailyExport () {
    return this._fetch(`${this.baseUri}/reports/sap/daily`)
  }
  // endregion

  // region user hierarchy
  getLeaders () {
    return this._fetch(`${this.baseUri}/users/leaders`)
  }
  // endregion

  // region exclusions

  getLeaderExclusions () {
    return this._fetch(`${this.baseUri}/leaderExclusion`)
  }
  getExcludedLeaders () {
    return this._fetch(`${this.baseUri}/leaderExclusion/excludedUsers`)
  }
  removeLeaderExclusion (id) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({ id })
    }
    return this._fetch(`${this.baseUri}/leaderExclusion`, options)
  }
  addLeaderExclusion ({match_text, field_name}) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({leaderExclusion: { match_text, field_name }})
    }
    return this._fetch(`${this.baseUri}/leaderExclusion`, options)
  }
  // endregion

  // region user data access
  getUserAccess (params) {
    return this._fetch(`${this.baseUri}/users/user-access?${stringify(params)}`)
  }

  getUserAccessById (azureId, params) {
    return this._fetch(`${this.baseUri}/users/${azureId}/user-access?${stringify(params)}`)
  }

  getAccessibleUsers (azureId) {
    return this._fetch(`${this.baseUri}/users/${azureId}/user-access/authorized`)
  }

  postUserAccess (body) {
    const { requestedAccessUserId, authorizedAccessToUserId } = body
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      body: JSON.stringify({
        requestedAccessUserId: requestedAccessUserId
      })
    }
    return this._fetch(`${this.baseUri}/users/${authorizedAccessToUserId}/user-access`, options)
  }

  deleteAdminUserAccessById (id) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    }
    return this._fetch(`${this.baseUri}/users/user-access/${id}`, options)
  }

  deleteLeaderUserAccessById (azureId, id) {
    const options = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      }
    }
    return this._fetch(`${this.baseUri}/users/${azureId}/user-access/${id}`, options)
  }
  // endregion

  getServerInfo () {
    return this._fetchRaw(`${this.baseUri}/server-info`)
  }
  getDBStatus () {
    return this._fetch(`${this.baseUri}/status/db`)
  }
}

export const cog = new Cognition()
if(process.env.NODE_ENV === 'development'){
  window.annotate = annotation => {
    cog.post('/annotate', annotation).catch(()=>{
      // ignore
      // /annotate will always 500, this is okay and expected,
      // it just needs to store the request in the DevTools network record
      console.log('Annotation recorded in HAR.')
    })
  }
}