import React, { Component } from 'react'
import ReactGA from 'react-ga'
import { Provider, connect } from 'react-redux'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  withRouter
} from 'react-router-dom'

import Login from './Login.jsx'
import Logout from './Logout.jsx'
import Status from './Status.jsx'
import VersionWarning from '../components/VersionWarning.jsx'
import GenericErrorBoundary from './GenericErrorBoundary.jsx'

import store from '../store.js'
import AlertsManager from '../components/AlertsManager.jsx'

ReactGA.initialize(GOOGLE_ANALYTICS_TRACKING_ID, {
  gaOptions: {
      cookieFlags: 'SameSite=Strict; Secure;'
  },
  debug: false
})

export default class Root extends Component {
  render() {
    return (
        <Provider store={store}>
          <Router>
            <GenericErrorBoundary>
              <this.props.nav />
              <VersionWarning />
              <main>
                <MainRoutes core={this.props.core}/>
                <AlertsManager />
              </main>
            </GenericErrorBoundary>
          </Router>
        </Provider>
    )
  }
}

class _MainRoutes extends Component {
  render(){
    const { user, core } = this.props
    // If user state does not exist in store, render <Login/>
    if (!(user && user.authenticated)) {
      // (Login will attempt to login silently and if that fails it will allow the user to manually initiate login flow,
      // once user is logged in, the redux store user object will be populated which will allow this component
      // to render the other arm of this "if statement")
      // See commit message of 54b7be3f for a lengthly explanation.
      return <Login/>
    } else {
      return <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/logout' component={Logout} />
        <Route exact path='/status' component={Status} />
        <Route path='/' component={core} />
      </Switch>
    }

  }

}
function mapStateToProps(state) {
  return { 
    user: state.user
  }
}

const MainRoutes = withRouter(connect(mapStateToProps)(_MainRoutes))