import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { cog } from '../../api/cognition'
import SelectAsync from './SelectAsync.jsx'

/**
 * Note, this component is tricky because the chosen BusinessUnit
 * is linked to the chose organization(s) (Sub Business Units)
 * A change in either is reflected in the other.  This is accomplished
 * by only changing the value of the subBusinessUnits in redux.
 * The value of the BusinessUnit is driven by the currently selected
 * subBusinessUnits.
 *
 * This way was chosen partially because (as of now) only sub business units
 * are ever referenced in other tables.  So to filter by "Business Unit"
 * we need to pass through the IDs of ALL the sub business units associated with
 * that Business Unit
 */

export default function SelectOrg (props) {
  const [businessUnits, setBusinessUnits] = useState([])
  // Use the same promise to fetch Business Units and Sub Business Units
  // (Business units are only listed within Sub Business Units entries as `business_unit`)
  const organizations = useMemo(() => cog.getOrganizations(), [])
  useEffect(() => { cog.get('/businessUnits').then(setBusinessUnits) }, [])
  // Load and map Business Units
  const loadOptionsBU = useCallback(inputValue => {
    return organizations.then(data => data.reduce((acc, cur) => {
      const preExistingBU = acc.find(x => x.business_unit_id === cur.business_unit_id)
      const subBusinessUnit = { value: cur.id, label: cur.name, business_unit_id: cur.business_unit_id }
      if (preExistingBU === undefined) {
        acc.push({ label: cur.business_unit.name, business_unit_id: cur.business_unit_id, subBusinessUnits: [subBusinessUnit] })
      } else {
        preExistingBU.subBusinessUnits.push(subBusinessUnit)
      }
      return acc
      // The BusinessUnits value must be an array of objects in the shape acceptable to react-select as options
      // because this drives what values are filled in the subBusinessUnit selector
    }, []).map(d => ({ value: d.subBusinessUnits, label: d.label }))
    // Filter based on user search (inputValue)
      .filter(d => d.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1))
  }, [organizations])
  // Load and map Sub Business Units
  const loadOptionsSubBU = useCallback(inputValue => {
    return organizations.then(data => data.map(d => ({ id: d.id, value: d.id, label: d.name, business_unit_id: d.business_unit_id })).filter(d => d.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1))
  }, [organizations])

  if (props.isMulti) {
    // Determine the current Business Unit based on which sub business units are selected
    // reduxSubBusinessUnits are the subBusinessUnits currently chosen in the Sub Business Unit input
    const reduxSubBusinessUnits = props.value
    const determinedBusinessUnitId = !reduxSubBusinessUnits ? null : reduxSubBusinessUnits.reduce((business_unit_id, cur) => {
      if (business_unit_id === null) {
        return cur.business_unit_id
      } else if (business_unit_id === cur.business_unit_id) {
        // Carry on, they match
        return business_unit_id
      } else {
        // Denote that there are multiple sub business units selected and so there is no top level Business Unit that covers them all
        return 'Mixed Business Units'
      }
    }, null)

    const businessUnit = (determinedBusinessUnitId === 'Mixed Business Units')
      ? {id: 'Mixed', name: 'Mixed Business Units'}
      : businessUnits.find(bu => bu.id === determinedBusinessUnitId)

    return <div className='row'>
      <div className='col-md-12'>
        <SelectAsync
          {...props}
          labelText={props.labelText || 'Business Unit'}
          value={businessUnit ? { value: businessUnit.id, label: businessUnit.name } : undefined}
          onChange={choice => {
            // Default choice's value to empty list so that the 'x' button on the BusinessUnit selector will clear subBU selections
            const { value } = choice || { value: [] }
            props.onChange(value)
          }}
          loadOptions={loadOptionsBU}
          // The general Business Unit selector should never be a multi selector
          isMulti={false}
        />
      </div>
      <div className='col-md-12'>
        <SelectAsync
          {...props}
          labelText={'Sub Business Unit'}
          loadOptions={loadOptionsSubBU}
          // If helpText exists, it should not be shown, as a duplicate, under the sub business unit.  Only under
          // the Business Unit Selector
          helpText={null}
          isMulti
        />
      </div>
    </div>
  } else {
    // Only display the subBusinessUnit selector
    // (but leave the label as 'Business Unit' so as to not change the previous appearance of the observation forms)
    return <SelectAsync {...props} labelText={props.labelText || 'Business Unit'} loadOptions={loadOptionsSubBU} />
  }
}

SelectOrg.defaultProps = {
  isMulti: true
}
