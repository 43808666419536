import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
// Error Box is the larger counterpart to Error Message
// Error Box often stands on it's own as a large box displaying an error
// ---

function renderBox (message, {flavor = 'alert-danger'}) {
  return <div className={classnames('alert', flavor)} role='alert'>
    <span className='fa fa-exclamation-circle' /> {message}
  </div>
}
export default function ErrorBox (props) {
  const {message, floating, style={}, flavor} = props
  if (!message) {
    return null
  }
  if (floating) {
    return (
      <div style={Object.assign({
        position: 'fixed',
        left: 0,
        bottom: '20px',
        right: 0,
        maxWidth: '400px',
        margin: '0 auto',
        zIndex: 1000,
        textAlign: 'center'
      },style)}>
        {renderBox(message, {flavor})}
      </div>
    )
  }
  return renderBox(message, {flavor})
}

ErrorBox.propTypes = {
  message: PropTypes.any,
  floating: PropTypes.bool
}
