import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import ErrorMessage from '../ErrorMessage.jsx'

class DescriptionTextbox extends React.Component {
  render () {
    const { value, error, onChange, placeholder, name } = this.props
    const classes = classNames({
      'form-group': true,
      'has-error': error
    })
    return (
      <div className={classes}>
        { error ? <ErrorMessage message={error} /> : null }
        <textarea id={name} value={value || ''} onChange={evt => {
          onChange(evt.target.value)
        }} placeholder={placeholder} className='form-control' />
      </div>
    )
  }
}

DescriptionTextbox.propTypes = {
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string
}

export default DescriptionTextbox
