import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Spinner from './Spinner.jsx'

import '../css/pagination.css'

class Pagination extends Component {
  getPaginationNumber (currentPage, number) {
    const { selectPage } = this.props
    return (
      <button
        className={`btn ${currentPage === number ? 'selected-page' : 'btn-default'} btn-sm`}
        key={number}
        id={number}
        onClick={() => selectPage(number)}
      >
        {number}
      </button>
    )
  }

  getPaginationEllipsis () {
    return (
      <button
        className='btn btn-link btn-sm disable-pagination-link'
        key={'ellipsis'}
      >
        ...
      </button>
    )
  }

  render () {
    const { numberOfRows, rowsPerPage, currentPage, selectPage, loadingPercentage } = this.props
    const totalPages = Math.ceil(numberOfRows / rowsPerPage)
    let pageNumbers = []
    const maxBeforeEllipses = 5

    if (totalPages <= maxBeforeEllipses) {
      pageNumbers = [...Array(totalPages).keys()].map((val, index) => this.getPaginationNumber(currentPage, index + 1))
    } else {
      const start = currentPage > 1 ? 1 : null
      const end = currentPage < totalPages ? totalPages : null

      const minus1 = currentPage > 2 ? currentPage - 1 : null
      const ellipsesStart = currentPage >= 4
      const minus2 = (ellipsesStart && totalPages - currentPage <= 1) ? currentPage - 2 : null
      const minus3 = (ellipsesStart && totalPages === currentPage) ? currentPage - 3 : null

      const plus1 = totalPages - currentPage > 1 ? currentPage + 1 : null
      const ellipsesEnd = totalPages - currentPage > 2
      const plus2 = (ellipsesEnd && currentPage + 2 <= 4) ? currentPage + 2 : null
      const plus3 = (ellipsesEnd && currentPage + 3 <= 4) ? currentPage + 3 : null

      const ary = [
        start,
        ellipsesStart,
        minus3,
        minus2,
        minus1,
        currentPage,
        plus1,
        plus2,
        plus3,
        ellipsesEnd,
        end
      ]

      pageNumbers = ary.map((element, index) => {
        if (index === 1) {
          if (ellipsesStart) {
            return this.getPaginationEllipsis()
          }
        }
        if (index === ary.length - 2) {
          if (ellipsesEnd) {
            return this.getPaginationEllipsis()
          }
        }
        if (element) {
          return this.getPaginationNumber(currentPage, element)
        }
        return element
      })
    }

    return (
      <ul className='pagination-ul'>
        <li>
          <button
            type='button'
            className='btn btn-default btn-sm'
            disabled={currentPage === 1}
            onClick={() => selectPage(currentPage - 1)}
          >
            <i className='fa fa-chevron-left' aria-hidden='true' />
          </button>
        </li>
        {pageNumbers}
        <li>
          <button
            type='button'
            className='btn btn-default btn-sm'
            disabled={currentPage === totalPages}
            onClick={() => selectPage(currentPage + 1)}>
            <i className='fa fa-chevron-right' aria-hidden='true' />
          </button>
        </li>
        {/* Optionally show a loading spinner */}
        {(loadingPercentage && loadingPercentage < 100) && (
          <li>
            <Spinner percentage={loadingPercentage} />
          </li>
        )}
      </ul>
    )
  }
}

Pagination.propTypes = {
  numberOfRows: PropTypes.number,
  rowsPerPage: PropTypes.number,
  currentPage: PropTypes.number,
  selectPage: PropTypes.func,
  loadingPercentage: PropTypes.number
}

export default Pagination
