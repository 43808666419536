import React, { Component, Fragment } from 'react'
import { Redirect } from 'react-router-dom'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'

import '../../css/userAccess.css'

import { cog } from '../../api/cognition'
import AddDataAccess from '../../components/forms/AddDataAccess.jsx'
import UserAccessTable from './UserAccessTable.jsx'
import Modal from '../../components/Modal.jsx'



class UserAccess extends Component {
  constructor (props) {
    super(props)
    this.addUser = this.addUser.bind(this)
    this.deleteUser = this.deleteUser.bind(this)
    this.modalCallback = this.modalCallback.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.getUserAccess = this.getUserAccess.bind(this)
    this.state = {
      accessInfo: {},
      userAccess: {},
      userAccessById: {}
    }
  }

  componentDidMount () {
    this.getUserAccess()
  }

  putPromiseInState(promise, key){
    this.setState({[key]:{loading:true,error:null,data:null}})
    promise.then(data => {
      this.setState({[key]:{loading:false,error:null,data}})
    }).catch(_=>{
      this.setState({[key]:{loading:false,error:'Could not load data',data:null}})
    })
  }
  addUser (values) {
    const { user } = this.props
    const authorizedAccessToUserId = user.admin ? values.authorized_access_to_user.value : user.profile.azure_id
    const addUserAccess = {
      requestedAccessUserId: values.requested_access_user.value,
      authorizedAccessToUserId
    }
    cog.postUserAccess(addUserAccess).then(() => {
      toast.success('Data access has been assigned')
      this.getUserAccess()
    }).catch(() => {
      toast.error('Could not assign data access, please try again later.')
    })
  }

  deleteUser (accessInfo) {
    this.setState({
      accessInfo: accessInfo
    })
  }

  getUserAccess () {
    const { user } = this.props
    const queryBy = this.userAccessQueryValues || { pageNum: 1 }

    if (user.admin) {
      this.putPromiseInState(cog.getUserAccess(queryBy),'userAccess')
    } else {
      this.putPromiseInState(cog.getUserAccessById(user.profile.azure_id, queryBy),'userAccessById')
    }
  }

  handleChange (values) {
    this.userAccessQueryValues = values
    this.getUserAccess()
  }

  modalCallback (data) {
    const { user } = this.props
    const { accessInfo: { id } } = this.state
    if (data === 'delete') {
      if (user.admin) {
        cog.deleteAdminUserAccessById(id).then(() => {
          this.getUserAccess()
          toast.success('Data access has been deleted')
        }).catch(()=>{
          toast.error('Could not delete data access, please try again later.')
        })
      } else {
        cog.deleteLeaderUserAccessById(user.profile.azure_id, id).then(() => {
          this.getUserAccess()
          toast.success('Data access has been deleted')
        }).catch(()=>{
          toast.error('Could not delete data access, please try again later.')
        })
      }
    }
    this.setState({
      accessInfo: {}
    })
  }

  render () {
    const { user } = this.props
    const { accessInfo, userAccess, userAccessById } = this.state
    let tableData
    if (userAccess.error && userAccess.error.status === 403) {
      return <Redirect to='/unauthorized' />
    } else {
      if (user.admin) {
        tableData = userAccess
      } else {
        tableData = userAccessById
      }
      let modalText = ''
      if (Object.keys(accessInfo).length > 0) {
        modalText = (
          <Fragment>
            <p>
              <strong>
              Do you wish to delete { accessInfo.userRequestedAccess.display_name }'s data access?
              </strong>
            </p>
            <p>
              <strong>This action cannot be undone.</strong>
            </p>
            <p>
            Their access to reporting data will be terminated immediately and may interrupt business operations.
            </p>
          </Fragment>
        )
      }
      const title = user.admin ? 'Personnel Data Access' : 'Assigned Data Access'

      return (
        <div className='container container-user-access'>
          <div className='page-header'>
            <h4><strong>{title}</strong></h4>
          </div>
          <AddDataAccess onSubmit={this.addUser} user={user} />
          <UserAccessTable
            userAccess={tableData}
            user={user}
            change={this.handleChange}
            deleteUser={this.deleteUser} />
          { Object.keys(accessInfo).length > 0
            ? <Modal
              callbackFromModal={this.modalCallback}
              bodyText={modalText}
              headerText='Delete Data Access'
              footerText={{ cancel: 'Cancel', affirm: 'Delete' }}
              callbackKey='delete' /> : null }
        </div>
      )
    }
  }
}

UserAccess.propTypes = {
  user: PropTypes.object,
  userAccess: PropTypes.object,
}

function mapStateToProps (state) {
  const { user, userAccess } = state
  return {
    user,
    userAccess,
  }
}

export default connect(mapStateToProps)(UserAccess)
