import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import { SuperForm, Form, CustomInput, Input } from '../../components/forms/SuperForm.jsx'
import ButtonWithSpinner from '../../components/ButtonWithSpinner.jsx'
import DateRange from '../inputs/DateRange.jsx'
import SelectLocation from '../../components/inputs/SelectLocation.jsx'
import SelectOrg from '../../components/inputs/SelectOrg.jsx'
import SelectPersons from '../inputs/SelectPersons.jsx'
import {ISO_DATE_FORMAT} from '../../constants.js'

class ResponseSearchForm extends Component {
  constructor(props) {
    super(props)
    const { user: { profile } } = this.props
    const userData = {
      value: profile.azure_id,
      label: profile.display_name
    }
    this.defaultValue = {
      id: '',
      range: {
        startDate: moment().startOf('day').startOf('isoweek').format(ISO_DATE_FORMAT),
        endDate: moment().endOf('day').format(ISO_DATE_FORMAT)
      },
      observers: [userData],
      observed: [],
      businessUnits: [],
      locations: []
    }
  }
  render() {
    const { loading } = this.props
    const observerHelpText = 'You may select multiple observers. Leave blank for all observers.'
    const observedHelpText = 'You may select multiple observed. Leave blank for all observed.'
    const locationHelpText = 'This is the location that the observation occurred.'
    const businessUnitHelpText = 'This is the business unit in which the observation occurred.'
    return (
      <SuperForm
        onSubmit={this.props.handleSubmit}
        onChange={this.props.handleChange}
        defaultValue={this.defaultValue}
        triggerSubmitForDefaultValues
      >
        {({ values }) => {
          const { id, observed, observers } = values
          const disableAllButID = !!id
          const observersDisabled = !!(observed && observed.length)
          const observedDisabled = !!(observers && observers.length)
          return <Form>
            <div className='row'>
              <div className='col-md-6'>
                <div><strong>Observer(s)</strong></div>
                <label>
                  <Input 
                    type='checkbox'
                    name='rolldown' 
                    disabled={observersDisabled || disableAllButID}
                  />&nbsp;Include observers' hierarchy</label>
                <CustomInput
                  component={SelectPersons}
                  name='observers'
                  helpText={observerHelpText}
                  labelText=''
                  placeholder={observersDisabled ? 'Disabled due to users in Observed filter' : undefined}
                  disabled={observersDisabled || disableAllButID} />
                <CustomInput
                  component={SelectPersons}
                  name='observed'
                  helpText={observedHelpText}
                  labelText='Observed'
                  placeholder={observedDisabled ? 'Disabled due to users in Observer(s) filter' : undefined}
                  disabled={observedDisabled || disableAllButID} />
                <CustomInput
                  component={SelectOrg}
                  name='businessUnits'
                  helpText={businessUnitHelpText} disabled={disableAllButID} />
              </div>
              <div className='col-md-6'>
                <div className='form-group'>
                  <label htmlFor={'id'}>ID</label>
                  <Input
                    name='id'
                    className="form-control"
                    type='text'
                  />
                </div>
                <CustomInput
                  component={SelectLocation}
                  name='locations'
                  helpText={locationHelpText}
                  isMulti
                  disabled={disableAllButID}
                />
                <CustomInput
                  component={DateRange}
                  name='range'
                  disabled={disableAllButID}
                />
              </div>
            </div>
            <ButtonWithSpinner type='submit' className={'margin-bottom-10'} loading={loading} title={'Search'} />
          </Form>
        }}
      </SuperForm>
    )
  }
}

ResponseSearchForm.propTypes = {
  loading: PropTypes.bool
}

function mapStateToProps(state) {
  const { user } = state
  return { user }
}

export default connect(mapStateToProps)(ResponseSearchForm)

