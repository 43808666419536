export const AUTH_LOGIN_USER = 'AUTH_LOGIN_USER'
export const AUTH_LOGOUT_USER = 'AUTH_LOGOUT_USER'
export function loginUser ({user}) {
  return {
    type: AUTH_LOGIN_USER,
    user
  }
}
export function logoutUser (err) {
  return {
    type: AUTH_LOGOUT_USER,
    error: err
  }
}
