import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment'

import {JDateRange} from '../JDateRange.jsx'
import {ISO_DATE_FORMAT} from '../../constants.js'


/*
Date range is an uncontrolled component because it needs to maintain it's own internal input state in order to
support both manual typing input and the datepicker popup.
Use it's output by passing a callback as it's onChange prop
*/
class DateRange extends React.Component {

  formLayout () {
    const { restrictToWeekSelection, clearable, label, error, onChange } = this.props
      return (
        <React.Fragment>
          <JDateRange
            {...this.props}
            isIsoWeek={restrictToWeekSelection}
            clearable={clearable}
            error={error}
            label={label}
            onChange={({startDate, endDate}) => {
              const dates = {
                // Ensure that the date string is not undefined before calling moment(dateString) with it or else
                // moment will return the current date!
                startDate: startDate ? moment(startDate).format(ISO_DATE_FORMAT) : undefined,
                // Ensure that the endDate is set to the end of the day 
                endDate: endDate ? moment(endDate).endOf('day').format(ISO_DATE_FORMAT) : undefined
              }
              onChange(dates)
            }}
          />
        </React.Fragment>
      )

  }

  render () {
    const { error } = this.props
    const classes = classNames({
      'form-group-daterange': true,
      'has-error': error 
    })
    return (
      <div className={classes} ref={div => { this.inputGroup = div }}>
        <div className='form-group'>
          {this.formLayout()}
        </div>
      </div>
    )
  }
}

DateRange.propTypes = {
  value: PropTypes.shape({
    startDate:PropTypes.string,
    endDate:PropTypes.string
  }),
  placeholder: PropTypes.object,
  // true if date range must cover 1 or more entire isoWeeks
  restrictToWeekSelection: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

export default DateRange
