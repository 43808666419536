import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import OAuth from '../auth/oauth.js'

import '../css/auth.css'


class Logout extends Component {
  render() {
    return (
      <div className='page-wrapper'>
        <div className='container container-login'>
          <div className='jumbotron'>
            <p>
              <button id='button-login-main' style={{margin: '0 auto', maxWidth:'200px'}} onClick={() => OAuth.logout()} className='btn btn-primary btn-lg btn-block'>
                Log out 
              </button>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

Logout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(Logout)