import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SafeHTML from '../../components/SafeHTML.jsx'

import ErrorMessage from '../../components/ErrorMessage.jsx'
import WithTooltip from '../../components/WithTooltip.jsx'

export function getOptionTextWithMasquerade(question, option){
    if(question && question.option_text_masquerade === 'identified'){
      if(option.value === 's'){
        return 'Identified'
      }else if(option.value === 'io'){
        return 'Not Identified'
      }
    }
    return option.text
}
class QuestionRadioSelect extends React.Component {
  constructor (props) {
    super(props)
  }
  optionText(question, option){
    return getOptionTextWithMasquerade(question, option)
  }
  generateOptions(){
    const {question_style, id} = this.props.question
    if(question_style === 'radio_buttons'){
      return this.generateRadios()
    }else if(question_style === 'dropdown'){
      return this.generateDropdown()
    }else{
      console.error('Invalid question_style', question_style, 'for question', id)
      // Default to radio buttons
      return this.generateRadios()
    }
  }

  generateDropdown () {
    const { question, isRequired, onChange, value } = this.props
    const shouldHaveNA = !isRequired
    const options = shouldHaveNA ? [{id: null, value: '', text: 'N/A'}, ...question.options] : question.options 
    return <select onChange={evt => {
      onChange(evt.target.value)
    }}
    // If value is null, default to '' in order to select 'N/A' or 'Choose an option'
    value={value || ''}
    >
      {!shouldHaveNA && <option value="" disabled>Choose an option</option>}
      {options.map(o => <option key={o.value} value={o.value}>{o.text}</option>)}
    </select>
  }

  generateRadios () {
    const { question, isRequired, value } = this.props
    const options = question.options.sort((a, b) => a.order - b.order)

    // If question is optional add 'N/A
    const radios = !isRequired ? options.concat({id: null, value: '', text: 'N/A'}) : options

    return radios.map(option => {
      const elementId = `${question.id}-option-${option.id}`
      return (
        <label key={elementId} className='radio-inline'>
          <input
            onChange={evt => {
              this.props.onChange(evt.target.value)
            }}
            type='radio'
            id={elementId}
            value={option.value}
            checked={option.value === value}
             />
            {this.optionText(question, option)}
        </label>
      )
    })
  }

  generateTooltip () {
    const { question } = this.props
    if (question.help_text === '') { return null }
    return (<WithTooltip text={question.help_text} position={'upper-left'}>
        <i className='question-info-sign fa fa-info-circle' ref={tooltip => { this.tooltip = tooltip }} />
      </WithTooltip>
    )
  }

  meta (error) {
    const tooltip = this.generateTooltip()
    return (
      <div className='col-md-12'>
        {tooltip} {error ? (
          <ErrorMessage message={error} />
        ) : null}
      </div>
    )
  }

  render () {
    const { question, error } = this.props
    const fieldName = question.field_name
    const formClasses = classNames({
      'form-group--radio': question.question_style === 'radio_buttons',
      'form-group': question.question_style === 'dropdown',
      'has-error': error
    })
    const rowClasses = classNames({
      'life-critical': question.is_life_critical && question.control_question_id === null,
      'has-quality-abbreviation': question.abbreviation === 29,
      'row': true
    })
    return (
      <div className={rowClasses}>
        {this.meta(error)}
        <div className='col-md-12'>
          <SafeHTML markdown={question.text} />
        </div>
        <div className='col-md-12'>
          <div className={formClasses}>
            {this.generateOptions(fieldName)}
          </div>
        </div>
      </div>
    )
  }
}

QuestionRadioSelect.propTypes = {
  onChange: PropTypes.func.isRequired,
  question: PropTypes.object.isRequired,
  validate: PropTypes.func,
  fields: PropTypes.object,
  error: PropTypes.string
}

export default QuestionRadioSelect
