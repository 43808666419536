import React from 'react'
import moment from 'moment'

import { Input, WithFormContext } from '../forms/SuperForm.jsx'
import ErrorMessage from '..//ErrorMessage.jsx'

const placeholderTime = 'Time, in the format: ' + moment().format('h:mm a')
export default WithFormContext((props) => {
  const { label, name, context } = props
  const error = context.errors[name]
  return <div className="form-group">
    <label htmlFor="label">{label}</label>&nbsp;
          {error ? <ErrorMessage message={error} /> : null}
    <div>
      <Input {...props} validate={validateTime} placeholder={placeholderTime} className='form-control'/>
    </div>
  </div>
})

export const validateTime = (value) => {
  const err = 'Time must be in the format "HH:MM am/pm", for example: 11:32 am'
  try {
    const [hours, afterColon] = value.split(':')
    // afterColon contains minutes and am/pm
    if (!(/^\d+$/.test(hours)
      // non-military time
      && parseInt(hours, 10) <= 12
      && /^\d\d ?[apAP][mM]$/.test(afterColon)
      && parseInt(afterColon, 10) <= 59
    )) {
      return err
    }
  } catch (_e) {
    return err
  }
}