import React from 'react'
import {render} from 'react-dom'
import * as Sentry from '@sentry/browser'

import './css/bootstrap.css'
import './css/base.css'
import './css/JDateRange.css'
import './css/content.css'

import Root from './containers/Root.jsx'
import TopNavigation from './containers/TopNavigation.jsx'
import Core from './containers/Core.jsx'

// Configure Sentry
try {
  if (SENTRY_DSN) {
    Sentry.init({
      dsn: SENTRY_DSN,
      release: SWO_FE_VERSION
    })
    console.log('Sentry configured successfully')
  } else {
    console.error('Sentry failed to configure')
  }
} catch(e) {
  console.error('Sentry failed to configure:', e)
}

window.appName = 'SWO'

render(
  <Root nav={TopNavigation} core={Core} />,
  document.getElementById('app')
)
