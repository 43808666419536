import React, { Component } from 'react'
import * as Sentry from '@sentry/browser'
import { withRouter } from 'react-router-dom'
import Logo from '../components/Logo.jsx'

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.    
    return { hasError: true, error };
  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service    
    console.log('Error Boundary Caught:', error, errorInfo);
    Sentry.captureException(error)
  }
  componentDidUpdate(prevProps) {
    if (
      (this.props.location && this.props.location.pathname) !== (prevProps.location && prevProps.location.pathname)
    ) {
      // On navigation event, remove error state
      this.setState({ hasError: false })
    }
  }
  render() {
    const { error, hasError } = this.state
    if (hasError) {
      // You can render any custom fallback UI      
      return <>
        <nav className='navbar navbar-default navbar-fixed-top navbar-dte'>
          <div className='container'>
            <div className='navbar-header'>
              <Logo/>
            </div>
          </div>
        </nav>
        <div className='page-wrapper'>
          <div className='container' style={{ marginTop: '16px' }}>
            {error.name === 'ChunkLoadError' ?
              <div className={'panel panel-body'}>
                This application has been updated, please refresh your browser to see the latest content.  If you still see this screen after refreshing, please clear your browser's cache.
          </div>
              :
              <div className={'alert alert-danger text-center'} role={'alert'} style={{ maxWidth: '600px', margin: '0 auto' }}>
                <h3 style={{ marginTop: 0 }}>Whoops!</h3>
                <h3>An error has occurred</h3>
                <p>
                  We have been notified and will investigate.
            </p>
                <p>
                  In the mean time, you can <a href='/'>refresh</a> and try again, or
              if this error persists, you can <a href='https://dteenergyprod.service-now.com/sp'>report an issue</a> with more detail which may help us get to the bottom of the issue faster.
            </p>
              </div>}
          </div>
        </div>
      </>
    }
    return this.props.children;
  }
}

export default withRouter(ErrorBoundary)