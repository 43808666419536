import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Question from './Question.jsx'

class Sections extends Component {
  questions (section) {
    const { response } = this.props
    return section.questions.sort((a, b) => a.order - b.order).map(question => {
      const answer = response.answers.find(answer => {
        return answer.question_id === question.id
      })
      if (!answer || answer.answer_text === 'Not Applicable') {
        return null
      }
      return (<Question key={question.id} response={response} question={question} />)
    }).filter(question => question !== null)
  }
  legend (section) {
    if (section.show_legend) {
      return (
        <div className='col-md-12'>
          <div className='alert alert-warning' role='alert'>
            <strong>S</strong> - Satisfactory | <strong>IO</strong> - Improvement Opportunity
          </div>
        </div>
      )
    }
    return null
  }
  sections () {
    const { sections } = this.props
    return sections
      .map(section => {
        if (section.questions.length === 0) { return null }
        return (
          <div key={section.id} className='row row-section'>
            <div className='section-header'>
              <h4>{section.name}</h4>
            </div>
            {this.legend(section)}
            <div className='col-md-12'>
              <div className='col-md-12'>
                {this.questions(section)}
              </div>
            </div>
          </div>
        )
      })
  }
  render () {
    return this.sections()
  }
}

Sections.propTypes = {
  sections: PropTypes.array.isRequired,
  response: PropTypes.object.isRequired
}

export default Sections
