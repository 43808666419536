import React, { Component } from 'react'
import PropTypes from 'prop-types'

import '../css/unauthorizedAccess.css'

class UnauthorizedAccess extends Component {
  goHome () {
    this.props.history.push('/')
  }
  render () {
    return (
      <div className='container container-unauthorized-access text-center margin-top-20'>
        <i className='fa fa-exclamation-circle' />
        <h1><b>Unauthorized</b></h1>
        <h4>You are not authorized to view this information</h4>
        <button className='btn btn-primary col-xs-2' onClick={() => this.goHome()}>Go Home</button>
      </div>
    )
  }
}

UnauthorizedAccess.propTypes = {
  history: PropTypes.object
}

export default UnauthorizedAccess
