

import React, { Component } from 'react'
import { cog } from '../../api/cognition'

import SelectPersons from './SelectPersons.jsx'

// SelectPersonsIntelligen wraps SelectPerons to provide additional features
// When passed an azure_id as a value with no label, it will look up the 
// person's name and store it in state
// This is currently used for SET_personnel where the azure_id is saved to the
// server but nothing else is.  An alternative would be to fetch the user information
// on the backend and attach it to the response, but this would need to be done for
// resuming saved observations AND editing finished observations.  It turns out to be
// simpler to just let SelectPersonIntelligent fetch the label if needed.
//  Note that this is still a controlled component and this extra feature will only 
// apply if the label is missing
class SelectPersonsIntelligent extends Component {
  constructor(props){
    super(props)
    this.state = {
      displayMapping:null
    }
  }
  componentDidMount(){
    this._updateDisplayMapping()
  }
  componentDidUpdate(prevProps){
    if(prevProps.value != this.props.value){
      this._updateDisplayMapping()
    }
  }
  _updateDisplayMapping(){
    const {isMulti,value } = this.props
    if(value){
      if(isMulti){
        // if multiple choice selector
        for(let valuePair of value){
          this._getLabelFromValue(valuePair)
        }
      } else {
        // If only single choice selector:
        this._getLabelFromValue(value)
      }
    }

  }
  _getLabelFromValue(valuePair){
    const {displayMapping} = this.state
    // Note, do not confuse this.props.value (the value passes in that makes this component a controlled component)
    // with this.props.value's {value,label} which is the pair that drives react-select's options
    const {value:azure_id, label} = valuePair
    // If react-select value (azure_id) doesn't have a corresponding label
    if(!label){
      // If displayMapping doesn't already have an entry for this azure_id
      if(!(displayMapping && displayMapping[azure_id])){
        // Fetch the label and store it in displayMapping
        cog.get('/users/'+azure_id).then(user => {
          this.setState(prev => ({
            displayMapping: {...prev.displayMapping, [azure_id]:user.display_name}
          }))
        })
      }
    }
  }

  valueWithMapping(){
    const {value, isMulti} = this.props
    const {displayMapping} = this.state
    if(displayMapping){
      if(isMulti){
        return value.map(({value:azure_id, label})=>{
          return {
            value:azure_id,
            label:label || displayMapping[azure_id]
          }
        })
      }else{
        const {value:azure_id, label} = value
        return {
          value:azure_id,
          label:label || displayMapping[azure_id]
        }
      }

    }
    return value

  }

  render(){
    return <SelectPersons
      {...this.props}
      value={this.valueWithMapping()}
    />
  }
}


export default SelectPersonsIntelligent