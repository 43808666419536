import React from 'react'
import PropTypes from 'prop-types'
import LoadingOverlay from './LoadingOverlay.jsx'
import '../css/jBarChart.css'

export const ProgressBar = props => {
  const { percentage } = props
  const style = { width: `${percentage || 0}%` }
  return (<div className='progress'>
    <div className='progress-bar progress-bar-dte' role='progressbar' aria-valuenow={percentage || 0} aria-valuemin='0' aria-valuemax='100' style={style} />
  </div>)
}
ProgressBar.propTypes = {
  percentage: PropTypes.number
}
export const StackedBarChart = props => {
  /*
  pairs:[
    {
      label:'trees',
      value:4,
      color:'#00ff00'
    }
  ]
  */
  const { pairs,style } = props
  const sum = pairs.sort((a,b)=>
    a.value - b.value
  ).reduce((sum, cur)=>{
    return sum + cur.value

  },0)
  return (
    <div style={{...{
      textAlign:'center',
      color:'white',
    },
    ...style}} className='jStackedBarChart'>
      {pairs.map(p => {
        return <div
        key={p.label}
        style={{
          height:p.value * 100 /sum + '%',
          backgroundColor:p.color
        }}
        >{p.label}: {p.value}</div>
      })}

    </div>
  )
}
export const BarChartBar = props => {
  const {isHorizontal, fullWhenUndefined, bar} = props
  const borderRadius = '4px'
  const outsideBarPx = '-23px'
  const withinBarPx = '4px'
  // Value may be null
  let value = bar.value ? parseInt(bar.value, 10) : 0
  const isEmpty = bar.value === null
  // Max should never be 0 or else bars will be full size even when fullWhenUndefined is false
  const max = props.max || 1
  if (fullWhenUndefined && isEmpty && bar.emptyLabel) {
    // default bar to full on null
    value = max
  }
  if (value === undefined) {
    return null
  }
  // Proportion is the size out of 100
  let proportion = 100 * (value || 0) / max
  if(proportion > 0 && proportion < 1){
    // Ensure that a non-0 bar shows at least the smallest amount
    // of fill.  This is relevant for bar charts where the difference
    // in bars is huge.
    proportion = 1
  }
  const spacing = '8px'
  const changeValuePositionThreshold = 60
  return (<div className='jBarChart-bar' style={{
    backgroundColor: '#eee',
    borderRadius: '4px'
  }}>
    {/* The spacer div */}
    <div style={{ height: 100 - proportion + '%' }} />
    {/* The bar fill div */}
    <div style={{
      height: isHorizontal ? 'inherit' : proportion + '%',
      width: isHorizontal ? proportion + '%' : 'inherit',
      backgroundColor: bar.color || '#00a0aa',
      borderRadius
    }}>
      {/* The bar inner-label div */}
      <div className='bar-innerLabel' style={
        isHorizontal
          ? proportion < changeValuePositionThreshold
            ? ({
              color: 'black',
              paddingLeft: spacing,
              marginLeft: '100%'
            })
            : ({
              right: spacing,
              position: 'relative',
              color: 'white',
              textAlign: 'right'
            })
          : proportion < changeValuePositionThreshold
            // If percentage is too little, display it above the bar
            ? ({
              top: outsideBarPx,
              position: 'relative',
              color: 'black'
            })
            // Else display it within the bar
            : ({
              top: withinBarPx,
              position: 'relative',
              color: 'white'
            })
      }>
        {isEmpty
          ? (bar.emptyLabel)
          // use bar.value here, not value as bar.value may contain other caracters such as '%'
          : (bar.value)
        }
      </div>
    </div>
  </div>)
}
BarChartBar.propTypes = {
  isHorizontal: PropTypes.bool,
  bar: PropTypes.object,
  max: PropTypes.number.isRequired,
  fullWhenUndefined: PropTypes.bool
}

export const BarChart = props => {
  // Make nulls empty objects
  const {max, isHorizontal, loading, fullWhenUndefined} = props
  const _max = max ? max : props.bars.reduce((biggest, bar) => {
    const value = bar.value ? parseInt(bar.value, 10) : 0
    return value > biggest ? value : biggest
  }, 0)
  const bars = props.bars.map(bar => bar || {})
  return isHorizontal ? (
    <div style={{position:'relative'}}>
      <LoadingOverlay isVisible={loading}/>
      <table className='jBarChart jBarChart-horizontal'>
        <tbody>
          {bars.map((bar, index) => {
            return <tr key={bar.label + index}>
              <td className='jBarChart-label shrink'>{bar.label}</td>
              <td className='expand'>
                <BarChartBar isHorizontal={isHorizontal} max={_max} fullWhenUndefined={fullWhenUndefined} bar={bar} />
              </td>
            </tr>
          })}
        </tbody>
      </table>
    </div>
  ) :
  (
    <div style={{position:'relative'}}>
      <LoadingOverlay isVisible={loading}/>
      <table className='jBarChart'>
        <tbody>
          <tr>
            {bars.map((bar, index) => {
              // It is appropriate and even vital to use the index as part of the key here, since
              // bars with information need to replace empty bars with not information that occupy the same space
              return (<td key={`bar-${index}`} >
                <BarChartBar max={_max} fullWhenUndefined={fullWhenUndefined} bar={bar} />
              </td>)
            })}
          </tr>
          <tr className='jBarChart-labels'>
            {/*
                It is appropriate and even vital to use the index as part of the key here, since
                bars with information need to replace empty bars with not information that occupy the same space
              */}
            {bars.map((bar, index) => (<td key={`column-label-${index}-${bar.label}`}>{bar.label != null ? bar.label : ''}</td>))}
          </tr>
        </tbody>
    </table>
    </div>
  )
}
BarChart.propTypes = {
  bars: PropTypes.arrayOf(PropTypes.shape({
    percentage: PropTypes.number,
    emptyLabel: PropTypes.string,
    color: PropTypes.string,
    label: PropTypes.string.isRequired
  })).isRequired
}
