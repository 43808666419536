import React from 'react'
import PropTypes from 'prop-types'

// The smaller counterpart to ErrorBox.jsx
// ErrorMessage is for form field errors
class ErrorMessage extends React.Component {
  render () {
    const { message } = this.props
    if (!message) {
      return null
    }
    return (
      <span className='field-error'>
        &nbsp;
        <i className='fa fa-exclamation-circle' /> <span>{message}</span>
      </span>
    )
  }
}

ErrorMessage.propTypes = {
  message: PropTypes.string
}

export default ErrorMessage
