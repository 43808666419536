import React, { Component, useState } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Cookies from 'js-cookie'
import { parse } from 'query-string'
import * as Sentry from '@sentry/browser'
import {
  Redirect
} from 'react-router-dom'

import * as AuthToast from '../containers/AuthToast.jsx'
import OAuth from '../auth/oauth.js'

import '../css/auth.css'


class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      authenticated: false,
      loading: true,
    }
    this._isMounted = false
  }
  componentWillUnmount() {
    this._isMounted = false
  }
  componentDidMount() {
    this._isMounted = true
    console.debug('Login.jsx: handleRedirectPromise')
    OAuth.handleRedirectPromise()
      .catch(e => {
        console.error('handleRedirectPromise catch:', e)
        Sentry.captureException(e)
      }).then(() => {
        // Regardless of what occurs earlier in the promise chain, set loading to false
        // since the request have either now succeeded or failed
        if(this._isMounted){
          this.setState({ loading: false })
        }
      })

    // Hide auth toast if it is shown, the login page should only have one button for reauthenticating
    AuthToast.hide()
  }
  getRedirectPath() {
    const params = parse(window.location.search)
    if (params.next) {
      return params.next
    }
    return '/'
  }
  login(){
    this.setState({ loading: true })
    OAuth.login()
      .catch(e => {
        console.error('Unexpected OAuth.login() error:', e)
        Sentry.captureException(e)
      })
      // always set loading back to false.  This .then comes AFTER the catch
      // so that it is called no matter how the promise settled
      .then(() => {
        this.setState({ loading: false })
      })
  }
  loginView() {
    return (
      <div className='page-wrapper'>
        <div className='container container-login'>
          <div className='jumbotron'>
            <p>Please login to continue.</p>
            <p>
              <button id='button-login-main' onClick={() => this.login()} className='btn btn-primary btn-lg btn-block'>
                Log in
              </button>
            </p>
          </div>
        </div>
      </div>
    )
  }
  errorView() {
    const {user} = this.props
    return (
      <div className='page-wrapper page-wrapper-login'>
        <div className='container container-login'>
          <div className='jumbotron text-center'>
            <div style={{ marginBottom: '16px' }}>
              <i className='fa fa-exclamation-triangle fa-2x fa-color-red' />
            </div>
            <p>{user.error}</p>
            <RefreshButton />
          </div>
        </div>
      </div>
    )
  }
  loadingUserView() {
    return (
      <div className='page-wrapper page-wrapper-login'>
        <div className='container container-login'>
          <div className='jumbotron text-center'>
            <p>Loading...</p>
            <p className='loading'>
              <i className='fa fa-cog fa-spin fa-2x fa-fw' />
              <span className='sr-only'>Loading...</span>
            </p>
          </div>
        </div>
      </div>
    )
  }
  render() {
    const {user} = this.props
    const { loading } = this.state

    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname + window.location.search)

    if (user && user.error) {
      return this.errorView()
    }
    const next = Cookies.get('next') || this.getRedirectPath()
    if (user && user.authenticated && !OAuth.invalid()) {
      console.log("Redirect to", next, 'with user', user)
      Cookies.remove('next')
      AuthToast.hide()
      return <Redirect to={next} />
    }
    if (loading) {
      return this.loadingUserView()
    }
    return this.loginView()
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired
}

function mapStateToProps(state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(Login)


function RefreshButton() {
  const [loading, setLoading] = useState(false)
  return <button
    type='button'
    className='btn btn-primary'
    onClick={() => {
      setLoading(true)
      location.reload()
    }}>
    {loading ? <i className='fa fa-circle-notch fa-spin' /> : 'Refresh'}
  </button>
}
