import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import '../css/sortIcons.css'

class SortIcons extends React.Component {
  render () {
    const { name, sortType, sortColumn, setSort } = this.props
    return (
      <div className='container-sort-icons pull-right' onClick={() => {
        // Only consider the sortType if it is the sortType of this sortColumn
        // otherwise it will toggle based on the state of another column's sortType
        const thisSortType = sortColumn === name ? sortType : null
        if(thisSortType === null){
          setSort(name, 'asc')
        }else if(thisSortType === 'asc'){
          setSort(name, 'desc')
        }else {
          setSort(name, null)
        }
      }}>
        <i className={classNames({
            hidden: sortType === 'desc' && sortColumn === name,
            'fa': true,
            'fa-sort-up': true,
            'fa-sort': true
          })}
          id={`${name}-sort-asc`}
          aria-hidden='true'
          />
        <i className={classNames({
            hidden: sortType === 'asc' && sortColumn === name,
            'fa': true,
            'fa-sort-down': true,
            'fa-sort': true
          })}
          id={`${name}-sort-desc`}
          aria-hidden='true'
          />
      </div>
    )
  }
}

SortIcons.propTypes = {
  name: PropTypes.string.isRequired,
  sortType: PropTypes.string,
  sortColumn: PropTypes.string,
  setSort: PropTypes.func.isRequired
}

export default SortIcons
