
import React, { useCallback } from 'react'
import { components } from 'react-select'
import { cog } from '../../api/cognition'
import SelectAsync from './SelectAsync.jsx'
import PropTypes from 'prop-types'



function SelectPersons (props) {
  const {onlyLeaders} = props
  const loadOptions = useCallback(input => {
    const promise = onlyLeaders ? cog.leaderSearch(input) : cog.userSearch(input)
    return promise.then(data =>
      (data.users || data.leaders)
        .filter(person => {
          if (!person.userPrincipalName) {
            return false
          }
          return !person.userPrincipalName.endsWith('.onmicrosoft.com')
        })
        .map(person => ({
          // value and label are specific to react-select
          value: person.azure_id,
          label: person.displayName,
          title: person.title || '',
          email: person.mail
        }))
    )
  }, [onlyLeaders])
  const CustomOption = (props) => {
    const { data } = props
    if (!data) {
      return null
    }
    const { label: name, value: azureId, title, email } = data
    if (!azureId) {
      return <components.Option {...props}>
        {data.label}
      </components.Option>
    }
    const photoUrl = cog.userPhotoUri(azureId, '48x48')
    return <components.Option {...props} key={azureId}>
      <div className='media-list select-persons-list'>
        <div className='media-left'>
          <img className='media-object select-persons-image' src={photoUrl} />
        </div>
        <div className='media-body'>
          <p className='select-persons-heading'>  {name}  </p>
          <p className='select-persons-sub-heading'>  {title}  </p>
          <p className='select-persons-sub-heading'>  {email}  </p>
        </div>
      </div>

    </components.Option>
  }
  CustomOption.propTypes = {
    data: PropTypes.object,
    innerProps: PropTypes.object
  }
  return <SelectAsync
    {...props}
    minInput={3}
    loadOptions={loadOptions}
    components={{ Option: CustomOption }}
    shouldDebounce
  />
}

SelectPersons.defaultProps = {
  labelText: 'Individual(s) Observed',
  helpText: null,
  placeholder: 'Name(s) or ID(s)...',
  isMulti: true,
  onlyLeaders: false
}

SelectPersons.propTypes = {
  onlyLeaders: PropTypes.bool

}

export default SelectPersons
