import React, { Component } from 'react'
import PropTypes from 'prop-types'

// Adds a form value to the component's state's "values" object and clears
// any errors associated with that specific value(s)
// --
// Returns a function to be passed directly into this.setState
// because it uses previous state
// --
// example valueObject: {stageName:'Stevie Wonder'}
function setValue(valueObject) {
  return prevState => {
    const adjustedErrors = Object.keys(valueObject).reduce((acc, cur) => {
      // If a value is supplied for [key], remove any errors relating on [key]
      delete acc[cur]
      return acc
    },{...prevState.errors})
    return{
    values:{...prevState.values, ...valueObject},
    // Clear error for this field
    errors:adjustedErrors

  }}
}
// Adds a form error (specific to a named value) to the component's state's "errors" object
// --
// Returns a function to be passed directly into this.setState
// because it uses previous state
// --
// example error: {name:'Required', email:'Please enter a valid email'}
function setError(error){
  return prevState => {
    // Combine old errors with the new
    const errors = {...prevState.errors, ...error}
    // Fully remove falsey error values such as null or false
    // This is more forgiving for custom components that may set their
    // internal error state to null for example
    const adjustedErrors = Object.keys(errors).reduce((acc, cur) => {
      if(!acc[cur]){
        delete acc[cur]
      }
      return acc
    },errors)
    return{
      errors:adjustedErrors
    }
  }
}

/*
A React form component with an emphasis on supporting original HTML input spec.
Inspired by Formik
*/
const FormContext = React.createContext()
FormContext.displayName = 'SuperFormContext'

export function WithFormContext(WrappedComponent){
  class _WithFormContext extends React.Component {
    render(){
      return <FormContext.Consumer>
        {context => <WrappedComponent {...this.props} context={context}/>}
      </FormContext.Consumer>
    }
  }
  _WithFormContext.displayName = `WithFormContext(${getDisplayName(WrappedComponent)})`;
  return _WithFormContext
}
function getDisplayName(WrappedComponent) {
  // From: https://reactjs.org/docs/higher-order-components.html
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
export class SuperForm extends Component{
  constructor(props){
    super(props)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.onChangeSingle = this.onChangeSingle.bind(this)
    this.onError = this.onError.bind(this)
    this.setRequired = this.setRequired.bind(this)
    this.reset = this.reset.bind(this)
    this.state = {
      handleSubmit:this.handleSubmit,
      onChangeSingle:this.onChangeSingle,
      onError:this.onError,
      setRequired:this.setRequired,
      defaultValue: this.props.defaultValue || {},
      values:this.props.defaultValue || {},
      errors:{},
      // a list of names of values that are required to submit
      required:[],
      // form values have changed from initial values
      isDirty:false
    }
  }
  componentDidMount(){
    if(this.props.triggerSubmitForDefaultValues){
      this.handleSubmit()
    }
  }
  componentDidUpdate(prevProps){
    const {enableReinitialize, defaultValue} = this.props
    if(enableReinitialize){
      if(JSON.stringify(prevProps.defaultValue) !== JSON.stringify(defaultValue)){
        this.setState({
          defaultValue:defaultValue || {},
          values:defaultValue || {},
          errors: {},
          isDirty: false
        }, () => {
          if(this.props.triggerSubmitForDefaultValues){
            this.handleSubmit()
          }
        })
      }
    }
  }
  handleSubmit(evt){
    const {errors, values, required} = this.state
    // Prevent form from submitting and using it's 'action' attribute which it shouldn't have
    // if this component is being used
    if(evt && typeof evt.preventDefault === 'function'){
      evt.preventDefault()
    }
    let isValid = true
    for(let r of required){
      const value = values[r]
      // Value is required if value is null or undefined
      // or if value is an empty array.
      if(typeof value === 'undefined' || value === null || value === '' || (Array.isArray(value) && !value.length)){
        this.setState(setError({[r]:'Required'}))
        console.error(`SuperForm validation error "${r}" field is required.`)
        isValid = false
      }
    }

    // Submit if there aren't any error values
    if(isValid && Object.values(errors).filter(x => typeof x !== 'undefined').length === 0){
      // pass the values (and secondarily the event) to the onsubmit prop
      // the event object is passed just incase the consumer wants to use the actual
      // event
      this.props.onSubmit(
        this.pretransformValues(values),
        evt)
      if(this.props.clearOnSubmit){
        this.reset()
      }
    }else{
      console.error('SuperForm did not submit due to errors or failed validation')
    }
  }
  // sets a value name as required or not
  setRequired(name, isRequired){
    const {required} = this.state
    const isAlreadyRequired = required.indexOf(name) !== -1
    if(isRequired){
      if(!isAlreadyRequired){
        // Add to required
        this.setState(prevState => ({required:[...prevState.required, name]}))
      }
    }else{
      if(isAlreadyRequired){
        // Remove from required
        this.setState(prevState => ({required:[...prevState.required.filter(n => n !== name)]}))
      }
    }
  }
  // Optionally transforms values before use in onChange or onSubmit
  // Useful for CustomInputs which hold multiple values that need
  // to be spread to the top level values state
  pretransformValues(values){
    if(this.props.pretransformValues){
      return this.props.pretransformValues(values)
    }else {
      return values
    }
  }
  // onChangeSingle is used internally for Inputs and CustomInputs and will store
  // the value of that component in state
  onChangeSingle(name, val){
    // Verify that the value actually changes:
    if(typeof val === 'object' 
      ? JSON.stringify(this.state.values[name]) !== JSON.stringify(val) 
      : this.state.values[name] !== val){
        
      if(!this.state.isDirty){
        this.setState({isDirty:true})
      }
      this.setState(setValue({[name]:val}),() => {
        // props.onChange is an optional callback for sending the form state externally.
        // It is to be used any time the state.values changes.
        if(this.props.onChange){
          this.props.onChange(
            // Note: This specifically does NOT use a closured version of this.state, it must use fresh state
            this.pretransformValues(this.state.values)
          )
        }
      })
    }
  }
  onError(name, err){
    this.setState(setError({[name]:err}))
  }
  reset(){
    this.setState({
      values: this.state.defaultValue || {},
      errors: {},
      isDirty: false
    }, this.props.onReset)
  }
  render(){
    const {values, errors, isDirty} = this.state
    const {debug} = this.props
    return <div>
        {debug && (
          <pre cy-id='superform-debug'>
            {!!this.props.pretransformValues && (<div> Note:values are pretransformed and may be in a different schema than defaultValues</div>)}
            {JSON.stringify(Object.assign({},this.state, {values:this.pretransformValues(this.state.values)}), null, 2)}
          </pre>
        )}
        <FormContext.Provider value={this.state}>
          {typeof this.props.children === 'function'
            ? this.props.children({values:this.pretransformValues(values), errors, reset:this.reset, isDirty})
            : this.props.children}
        </FormContext.Provider>
      </div>
  }
}
SuperForm.propTypes = {
  onSubmit:PropTypes.func,
  // Optionally, call with state.values everytime values change
  onChange:PropTypes.func,
  // Called after the form is reset
  onReset:PropTypes.func,
  // resets the form on submit event
  clearOnSubmit: PropTypes.bool,
  pretransformValues: PropTypes.func,
  // The default values of the form state.  In Formik this is called initialValues, but in SuperForm,
  // it is named defaultValue in order to correspond to HTML input element's defaultValue
  defaultValue: PropTypes.object,
  // Fires onSubmit with the initial defaultValue, and when defaultValue changes (if enableReinitialize is true)
  // This is especially convenient used in conjunction with pretransformValues
  triggerSubmitForDefaultValues: PropTypes.bool,
  // like Formik, resets all values if defaultValue changes
  enableReinitialize: PropTypes.bool,
  debug:PropTypes.bool
}

class _Form extends Component{
  constructor(props){
    super(props)
  }
  render(){
    const onSubmit = this.props.context && this.props.context.handleSubmit
    return <form onSubmit={onSubmit}>
      {this.props.children}
    </form>
  }

}
_Form.propTypes = {
  onSubmit:PropTypes.func,
  // context is supplied by WithFormContext
  context:PropTypes.object.isRequired,
}

class _Input extends Component{
  componentDidMount() {
    const {context, name, isRequired, type} = this.props
    // checkboxes are an exception that do not support isRequired because their undefined value
    // means the same as unchecked/false
    if(type !== 'checkbox'){
      context.setRequired(name, isRequired)
    }
  }
  componentDidUpdate(prevProps){
    const {context, name} = this.props
    if(prevProps.isRequired !== this.props.isRequired){
      context.setRequired(name, this.props.isRequired)
    }
    if(this.props.clearWhenDisabled){
      if(prevProps.disabled !== this.props.disabled && this.props.disabled){
        context.onChangeSingle(name, undefined)

      }
    }
  }
  componentWillUnmount(){
    const {context, name} = this.props
    context.setRequired(name, false)

    // Report unmounting to SuperForm
    context.onChangeSingle(name, undefined)
  }
  render(){
    // For most inputs, the 'value' prop is controlled via the FormContext.  This is the default behavior.
    // For radio buttons, 'value' is passed as an unchanging, unconrolled prop to denote which radio button is  
    // selected and the 'checked' prop is controlled via FormContext.
    // ---
    // isRequired (and other props) are destructured here (even if they are unused)
    // so that they doesn't get passed into the <input/> with the rest of the props that will be passed down
    // to the input element
    const {
      name, value, type='text', className, context, validate,
      // These properties are descructured simply so that they are not passed on to the Component
      // isRequired is handled by SuperForm and need not be passed to the Component, though `required` will still
      // function as expected on the input if the developer wishes to use the Browser's built-in validation.
      // defaultValue and defaultChecked are excluded so that the input will always be in sync with the SuperForm
      // value state
      defaultValue:_defaultValue, defaultChecked:_defaultChecked, isRequired:_isRequired,// eslint-disable-line no-unused-vars
      // Grab the rest of the props to pass onto the Component
      ...restProps} = this.props
    const isCheckbox = type === 'checkbox'
    const isRadio = type === 'radio'
    const Component = type === 'textarea' ? 'textarea' : 'input'
    return <Component
      // Spread props with lowest priority so that explicit props override them
      {...restProps}
      className={className} 
      type={type}
      id={this.props.id || name}
      name={name}
      value={!(isCheckbox || isRadio) 
        // If not a checkbox nor radiobutton, values is controlled by context
        ? (context.values[name] || '') 
        // If a checkbox, value is likely undefined, but can be existant if a string should be passed when
        // checked rather than true or false.
        // If a radio button, value is the name of the particular selected radio button
        : (value || undefined)}
      checked={isCheckbox 
        // If checkbox, context determines true/false value (stored in the element under the 'checked' attribute
        // but kept in context.value so that all form values are in one place)
        ? (context.values[name] || false)
        : isRadio
          // Radio buttons 'value' is preset as the string that will be passed if the radiobutton is checked
          // 'checked' for radio button will be true/false depending on whether this specific radio button is
          // selected
          ? (context.values[name] === value || false)
          // default to undefined (particularly for non-radio non-checkbox inputs)
          : undefined}
      onChange={evt => {
        const name = evt.target.name
        const val = evt.target.value || (isCheckbox ? evt.target.checked : '')
        if(this.props.onChange){
          // Send the changed value to an optional onChange prop for the Input itself
          this.props.onChange(val)
        }
        // Note, onChangeSingle must come before validate/onError,
        // otherwise it will clear the error right after it's set
        // in the event of a validation error
        // ---
        // Report the change to the SuperForm context
        context.onChangeSingle(name, val)
        // Execute validation prop function if it exists
        // to ensure that the value is valid
        if(typeof validate === 'function'){
          const errorString = validate(val)
          if(errorString){
            context.onError(name, errorString)
          }
        }
      }}
    />
  }
}
_Input.propTypes = {
  // context is supplied by WithFormContext
  context:PropTypes.object.isRequired,
  name:PropTypes.string.isRequired,
  // isRequired is named as such rather than 'required' because 'required' is a defined HTML
  // attribute for inputs.  This component supports both.  isRequired will manage the required
  // state in the js, whereas 'required' will manage it with the browser's predefined behavior
  isRequired:PropTypes.bool,
  // causes the value to be cleared in the SuperForm state when the component becomes disabled
  clearWhenDisabled:PropTypes.bool,
}
class _CustomInput extends Component{
  constructor(props){
    super(props)
    this.onChange = this.onChange.bind(this)
    this.onError = this.onError.bind(this)
  }
  componentDidMount() {
    const {context, name, isRequired} = this.props
    context.setRequired(name, isRequired)
  }
  componentDidUpdate(prevProps){
    const {context, name} = this.props
    if(prevProps.isRequired !== this.props.isRequired){
      context.setRequired(name, this.props.isRequired)
    }
    if(this.props.clearWhenDisabled){
      if(prevProps.disabled !== this.props.disabled && this.props.disabled){
        context.onChangeSingle(name, undefined)

      }
    }
  }
  componentWillUnmount(){
    const {context, name} = this.props
    context.setRequired(name, false)

    // Report unmounting to SuperForm
    context.onChangeSingle(name, undefined)
  }
  onChange(value) {
    const {validate, name, context} = this.props
    // Note, onChangeSingle must come before validate/onError,
    // otherwise it will clear the error right after it's set
    // in the event of a validation error
    context.onChangeSingle(name, value)
    // Execute validation prop function if it exists
    // to ensure that the value is valid
    if(typeof validate === 'function'){
      const errorString = validate(value)
      if(errorString){
        context.onError(name, errorString)
      }
    }
  }
  onError(error){
    const {name, context} = this.props
    return context.onError(name, error)
  }
  render(){
    const {
      className,
      context,
      name
    } = this.props

    // Remove this.props.component from props before prop drilling
    // if `component` is not removed, it opens the possibility for
    // infinite recursive rendering (when a CustomInput incorrrectly 
    // renders another custom input directly).
    // The descructuring, omits component from `sanitizedPropsForDrilling`
    const {component:Component, 
      // Remove defaultValue, components used in CustomInput must be controlled components.
      defaultValue:_defaultValue, // eslint-disable-line no-unused-vars
      ...sanitizedPropsForDrilling} = this.props
    if(!Component){
      // If this component returns null unexpectedly,
      // ensure that you are not accidentally nesting CustomInput components.
      // component is stripped before being passed down to prevent and infinite render
      // loop.
      return null
    }
    return <Component
      {...sanitizedPropsForDrilling}
      context={context}
      name={name}
      value={context.values[name]}
      error={context.errors[name]}
      className={className}
      onChange={this.onChange}
      // onError is passed to CustomInput to allow them to pass their own custom errors up to
      // SuperForm to prevent form submission.
      onError={this.onError}
    />
  }
}
_CustomInput.propTypes = {
  // context is supplied by WithFormContext
  context:PropTypes.object.isRequired,
  name:PropTypes.string.isRequired,
  // A function that returns an error string if the value is invalid OR returns undefined if the value is valid
  validate: PropTypes.func,
  // isRequired is named differently from HTML input's required because isRequired is managed here in JS and 
  // html input's required is managed by the browser.
  isRequired:PropTypes.bool,
  // disabled matches HTML input's disabled and will be passed down to the component
  disabled:PropTypes.bool,
  // causes the value to be cleared in the SuperForm state when the component becomes disabled
  clearWhenDisabled:PropTypes.bool,
  component:PropTypes.any.isRequired
}
export const Input = WithFormContext(_Input)
export const CustomInput = WithFormContext(_CustomInput)
export const Form = WithFormContext(_Form)
export const testables = {
  setValue,
  setError
}

/*
Note: To use a regular input that is form context aware, you can wrap it in WithFormContext. For example:

const TimeExact = WithFormContext((props) => {
  const { label, name, context } = props
  const error = context.errors[name]
  return <div className="form-group">
    <label htmlFor="label">{label}</label>&nbsp;
          {error ? <ErrorMessage message={error} /> : null}
    <div>
      <Input {...props} className='form-control'/>
    </div>
  </div>
})
 */

 /*
 TODO: 
- isRequired prop for checkboxes or elements with a boolean value will have undesired results because the value starts 
undefined unless it is specified in defaultValue, in which case it would prevent submission despite the face that a
newly initialized checkbox is implicitly false
- isDirty needs some work, it doesn't work right if form returns to clean state

 */