import React, { Component } from 'react'
import queryString from 'query-string'
import { cog } from '../api/cognition'

class Rating extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isRatingAlreadySubmitted: false
        }
    }
    componentDidMount () {
        const params = queryString.parse(location.search)
        cog.getResponsesById(params.response).then(response => {
            const isRatingSubmitted = response.answers.some((answer) => { return answer.question_id === 774 || answer.question_id === 886 })
            if (!isRatingSubmitted) {
                return cog.editResponses(response, params.answer)
            }
            this.setState({
                isRatingAlreadySubmitted: isRatingSubmitted
            })
        }).then((result) => {
            console.log('Rating submitted successfully', result)
        }).catch(err => console.error('Unable to submit rating ', err))
    }

    render () {
        return this.state.isRatingAlreadySubmitted ? (
            <div style={{
                'max-width': 'max-content',
                textAlign: 'center',
                margin: '64px auto',
                color: '#a94442',
                border: '2px solid #a94442',
                padding: '0 15px 15px 15px'
            }}>
                <h1>Uh-oh</h1>
                <div>You have already submitted your rating for this SET Observation</div>
            </div>
        ) : (
            <div style={{
                width: '100%',
                textAlign: 'center',
                margin: '64px auto'
            }}>
                <h1>Thank you</h1>
                <div>Your rating was submitted successfully</div>
            </div>
        )
    }
}

export default Rating
