import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Question from './Question.jsx'
import ErrorMessage from '../../components/ErrorMessage.jsx'

class Sections extends Component {
  questions (section) {
    const {initialValues, questionErrors} = this.props
    return section.questions.sort((a, b) => a.order - b.order).map(question => {
      return (<Question 
        key={`s-${section.id}-${question.id}`}
        initialValues={initialValues}
        question={question} 
        onChange={ this.props.onChange }
        clear={ this.props.clear }
        errors={questionErrors || {}}
      />)
    }).filter(question => question !== null)
  }

  sectionError (section) {
    const { sectionErrors = {}, submitting } = this.props
    if (submitting) { return null }
    const error = sectionErrors[section.id]
    if (error) {
      return (<ErrorMessage message={error} />)
    }
    return null
  }

  legend (section) {
    const sectionRequired = section.number_required === -1
    let legend
    if (section.show_legend) {
      legend = sectionRequired
        ? (

          <div className='alert alert-warning' role='alert'>
            <strong>S</strong> - Satisfactory | <strong>IO</strong> - Improvement Opportunity
          </div>
        )
        : (
          <div className='alert alert-warning' role='alert'>
            <strong>S</strong> - Satisfactory | <strong>IO</strong> - Improvement Opportunity | <strong>N/A</strong> Not Applicable
          </div>
        )
    }
    if (legend) {
      return (
        <div className='col-md-12'>
          {legend}
        </div>
      )
    }
    return null
  }

  render () {
    const { sections } = this.props
    return sections
      .map(section => {
        if (section.questions.length === 0) { return null }
        return (
          <div key={section.id} className='row row-section'>
            <div className='section-header'>
              <h4>{!!section.name && (
                section.name)} {this.sectionError(section)}
              </h4>
            </div>
            {this.legend(section)}
            <div className='col-md-12'>
              <div className='col-md-12'>
                {this.questions(section)}
              </div>
            </div>
          </div>
        )
      })
  }
}

Sections.propTypes = {
  sections: PropTypes.array.isRequired,
  sectionErrors:PropTypes.object,
  questionErrors:PropTypes.object
}

export default Sections
