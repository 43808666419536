import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import { SuperForm, Form, Input } from '../../components/forms/SuperForm.jsx'
import EmployeeInfo from './EmployeeInfo.jsx'
import ErrorBox from '../../components/ErrorBox.jsx'
import Sections from './Sections.jsx'
import oauth from '../../auth/oauth'

class ObservationForm extends Component {
  constructor(props) {
    super(props)
    // this.answers is not a part of the component's state because it doesn't drive any rendering,
    // it is simply the aggregate store of all question answer data, to be used on form submittion
    this.answers = {}
    this.manageVisibilityChange = this.manageVisibilityChange.bind(this)
  }
  componentDidMount() {
    window.addEventListener('focus', this.manageVisibilityChange)
    document.addEventListener('visibilitychange', this.manageVisibilityChange);
  }
  componentWillUnmount() {
    window.removeEventListener('focus', this.manageVisibilityChange)
    document.removeEventListener('visibilitychange', this.manageVisibilityChange);
  }
  manageVisibilityChange() {
    if (this.changedSinceSave && oauth.invalid()) {
      this.changedSinceSave = false
      this.props.saveProgress(this.values, this.answers)
    }
  }

  submitButton() {
    const { submitting } = this.props
    const buttonProps = {
      className: 'btn btn-primary btn-block',
      disabled: submitting,
      type: 'submit'
    }
    if (submitting) {
      return (
        <button {...buttonProps}>
          <span className='visible-xs visible-md visible-lg'><i className='fa fa-circle-notch fa-spin fa-lg fa-fw' /> Submitting - Please Wait</span>
          <span className='visible-sm'><i className='fa fa-circle-notch fa-spin fa-lg fa-fw' /> Submitting...</span>
        </button>
      )
    }
    return (
      <button {...buttonProps}>
        Submit
      </button>
    )
  }

  employeeInfoProps(values) {
    const { questionnaire, slug } = this.props
    const { freeForm, free_form_observed_name, was_set_conducted } = values
    const props = {
      allowAnonymousObserver: false,
      allowAnonymousObserved: false,
      showObservationType: true,
      freeFormSelected: !!freeForm,
      slug,
      free_form_observed_name,
      observedLabelText: 'Individual(s) Observed',
      was_set_conducted
    }
    if (questionnaire) {
      props.allowAnonymousObserver = questionnaire.allow_anonymous_observer
      props.allowAnonymousObserved = questionnaire.allow_anonymous_observed
      props.allowFreeFormObserved = questionnaire.allow_free_form_observed
      if (questionnaire.category_id !== null) {
        props.showObservationType = false
      }
      if (questionnaire.name === 'Pre-Job Brief' || questionnaire.name === 'Hazardous Energy Control' || questionnaire.name === 'Storm') {
        props.observedLabelText = 'Individual(s) Participating'
      }
      // Denote that observed is required with an asterix
      if (!props.allowAnonymousObserved) {
        props.observedLabelText += '*'
      }
    }
    return props
  }

  displayFormError(errors) {
    if (errors && Object.keys(errors).length) {
      return <ErrorBox message={'Please adjust the form to meet requirements before submitting.'} />
    }
    return null
  }

  render() {
    const {
      onSubmit,
      onCancel,
      onDiscard,
      triggerRecheckSections,
      questionnaire,
      template,
      defaultValue,
      sectionErrors,
      questionErrors,
      slug
    } = this.props
    return (
      <SuperForm
        defaultValue={defaultValue}
        onSubmit={(values, _evt) => {
          onSubmit(values, this.answers)
        }}
        onChange={(values) => {
          this.values = values
          this.changedSinceSave = true
        }}
        enableReinitialize
      >
        {({ values, errors: formErrors }) => {
          return <Form>
            <div className='row'>
              <div className='col-md-12'>
                <div className='page-header'>
                  {slug === 'Home-Safety-Walk-Down' ?
                    (<div><h4>Intent and use of this document and findings:</h4>
                      <p>1.The intent of this inspection is to guide employees through a voluntary self-assessment of their home to ensure that their home and work spaces are safe.  This inspection serves as a tool to proactively identify potential hazards and necessary countermeasures and to document the findings and recommended corrective actions.  This assessment extends beyond work area hazards and encourages general home safety check as well.</p>
                      <p>2.The walkdowns will be reviewed by the safety committee for C3 opportunities.</p></div>) :
                    (questionnaire && <h4>{questionnaire.name}</h4>)}
                </div>
                <div>
                {(questionnaire && <p>{questionnaire.guideline}</p>)}
                </div>
              </div>
            </div>
            <EmployeeInfo {...this.employeeInfoProps(values)} />
            <div className='row'>
              <div className='col-md-12'>
                {template ? (
                  <Sections
                    sections={template.sections}
                    initialValues={defaultValue}
                    onChange={answerInfo => {
                      this.changedSinceSave = true
                      // Note: Sections do not use SuperForm components, they keep track
                      // of their own state
                      this.answers[answerInfo.question_id] = answerInfo
                      if (sectionErrors || questionErrors) {
                        // If there are errors, recheck the sections
                        // on each Sections change to remove the errors as they
                        // are fixed
                        triggerRecheckSections(this.answers)
                      }
                    }}
                    clear={question_id => {
                      // Remove the answer data for this question from the answers object
                      delete this.answers[question_id]
                    }}
                    sectionErrors={sectionErrors}
                    questionErrors={questionErrors}
                  />
                ) : null}
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <div className='form-group'>
                  <label htmlFor='comments'>Comments</label>
                  <Input id='comments'
                    className='form-control'
                    name='comments'
                    type='textarea' />
                </div>
              </div>
            </div>
            <div className='row'>
              <div className='col-md-12'>
                <hr />
                {/*
              Display both types of errors:
              formErrors come from SuperForm
              sectionErrors / questionErrors come from whole form validation which occurs before submitting
              */}
                {this.displayFormError(Object.assign({}, formErrors, sectionErrors, questionErrors))}
              </div>
              <div className='col-xs-12 col-sm-3 col-sm-push-9'>
                <div className='form-group'>
                  {this.submitButton()}
                </div>
              </div>
              {this.props.editId && (
                <div className='col-xs-12 col-sm-3 col-sm-pull-3'>
                  <div className='form-group'>
                    <button type='button' className='btn btn-danger' onClick={onCancel}>
                      <i className='fa fa-ban' /> Cancel Edit
                    </button>
                  </div>
                </div>
              )}
              {!this.props.editId && (
                <div className='col-xs-12 col-sm-3 col-sm-pull-3'>
                  <div className='form-group' style={{ display: 'flex' }}>
                    <button type='button' className='btn btn-danger' onClick={onDiscard}>

                      <i className='fa fa-trash' /> Discard
                    </button>
                    <SaveButton save={() => {
                      // Important to return the promise so save button can react to the result
                      return this.props.saveProgress(this.values, this.answers)
                    }} />
                  </div>
                </div>
              )}
            </div>
          </Form>
        }}
      </SuperForm>
    )
  }
}

ObservationForm.propTypes = {
  defaultValue: PropTypes.object,
  sectionErrors: PropTypes.object,
  questionErrors: PropTypes.object,
  onCancel: PropTypes.func,
  onDiscard: PropTypes.func,
  onSubmit: PropTypes.func.isRequired,
  questionnaire: PropTypes.object,
  slug: PropTypes.string,
  submitting: PropTypes.bool,
  submittingSuccess: PropTypes.bool,
  template: PropTypes.object,
  editId: PropTypes.number
}
export default ObservationForm

class SaveButton extends Component {
  constructor(props) {
    super(props)
    this.state = {
      saving: false,
      success: null,
    }
    this.resultTimeoutId
    this.style = {
      transition: 'all 0.3s ease 0s',
      marginLeft: '8px',
      marginTop: 0
    }
  }
  render() {
    const { success, saving } = this.state
    if (saving) {
      return <button key='save-btn' disabled={true} type='button' className='btn btn-default' style={this.style}>
        <i className='fa fa-circle-notch fa-spin' /> <span> Saving...</span>
      </button>
    }
    if (success) {
      return <button key='save-btn' type='button' className='btn btn-success' style={this.style}>
        <i className='fa fa-check' /> Saved!
      </button>
    }
    return <button key='save-btn' type='button' className='btn btn-default' style={this.style}
      onClick={() => {
        if (saving) {
          // Do not save again if save is in progress
          return
        }
        this.setState({ saving: true })
        this.props.save().then(() => {
          this.setState({ success: true })
        }).catch(() => {
          toast.error('Could not save observation, please try again later.')
        }).then(() => {
          // Finally, because the request is finished regardless if it succeeded, set saving back to false
          this.setState({ saving: false })
          if (this.resultTimeoutId) {
            clearTimeout(this.resultTimeoutId)
          }
          this.resultTimeoutId = setTimeout(() => {
            // Clear success after a timeout
            this.setState({ success: null })
          }, 2000)
        })
      }}>
      <i className='fa fa-save' /> <span> Save for later</span>

    </button>
  }

}
ObservationForm.propTypes = {
  // save is a function that must return a promise that reflects the success result of the save operation
  save: PropTypes.func,
}

