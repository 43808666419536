import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import _get from 'lodash.get'

import '../../css/response.css'

import { cog } from '../../api/cognition'
import ErrorBox from '../../components/ErrorBox.jsx'
import ResponseInfo from './ResponseInfo.jsx'
import Sections from './Sections.jsx'
import * as form from '../../services/form'



class Response extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: false,
      error:null,
      response:null,
      questionnaire: null,
      template: null
    }
    this.goBack = this.goBack.bind(this)
  }

  componentDidMount () {
    const { match: { params } } = this.props
    this.setState({loading:true,error:null})
    cog.getResponsesById(params.id).then(response => {
      this.setState({response})
      const slug = _get(response, 'questionnaire.slug')
      return cog.getQuestionnaireBySlug(slug)
    }).then(questionnaire => {
      const template = form.buildTemplateFromQuestionnaire(questionnaire)
      this.setState({questionnaire, template})
    }).catch(error => {
      this.setState({error})
    }).then(() => {
      this.setState({loading:false})
    })
  }

  goBack () {
    const { history } = this.props
    if (history.action === 'PUSH') {
      history.goBack()
    } else {
      window.close()
      // Fallback for when window.close will not work because
      // the window was not opened with js
      // Take user to homepage
      window.location.replace('/')
    }
  }

  actions () {
    const {user} = this.props
    const {response, questionnaire} = this.state
    return (
      <div className='row actions-row'>
        <div className='col-md-12 margin-top-10 margin-bottom-10'>
          <button className='btn btn-default hidden-xs' onClick={this.goBack}>Go Back</button>
          <button className='btn btn-default hidden-md hidden-sm hidden-lg' onClick={this.goBack}>Go Back</button>
          {(user.admin || user.superUser) && response && questionnaire && <Link to={`/observation/${questionnaire.slug}/?edit=${response.id}`} className='btn btn-warning margin-left-5'>
            <i className='fa fa-edit' aria-hidden='true' /> Edit
          </Link>}
        </div>
      </div>
    )
  }

  noResponseError () {
    return (
      <div className='container container-response'>
        {this.actions()}
        <div className='row'>
          <div className='col-md-12'>
            <ErrorBox message={'Response not available'} />
          </div>
        </div>
      </div>
    )
  }

  loadingResponse () {
    return (
      <div className='container container-response'>
        <div className='row'>
          <div className='col-md-12 text-center'>
            <h5>Loading response...</h5>
            <i className='fa fa-circle-notch fa-spin fa-fw' />
          </div>
        </div>
      </div>
    )
  }

  loadingQuestions () {
    return (
      <div className='row'>
        <div className='col-md-12 text-center'>
          <hr />
          <h5>Loading questions...</h5>
          <i className='fa fa-circle-notch fa-spin fa-fw' />
        </div>
      </div>
    )
  }

  noQuestionsError () {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <hr />
          <ErrorBox message={'Could not load questions'} />
        </div>
      </div>
    )
  }

  comments () {
    const {response} = this.state
    if (!response.addtl_comments) { return null }
    return (
      <div className='row'>
        <div className='col-md-12'>
          <div className='page-header'>
            <h4>Comments</h4>
          </div>
          <div className='panel panel-default panel-question-text'>
            <div className='panel-body'>
              <samp>{response.addtl_comments}</samp>
            </div>
          </div>
        </div>
      </div>
    )
  }

  details () {
    const { questionnaire, template, response, loading, error } = this.state

    // Add questions to the template that have been removed from the questionnaire
    // since this response was submitted
    // ---
    // Get all questions from the answers in this response and sort them by hierarchy
    const allResponseQuestions = response.answers.map(a => a.question).sort((a,b)=> {
      return a.hierarchy === b.hierarchy
        ? 0
        : a.hierarchy < b.hierarchy
        ? -1 : 1
    })
    // Find the deleted questions that do not currently exist in the template
    // Note: If a question has it's relation removed from the questionnaire, it's
    // child questions will still be in template.questions, so this only 
    // retrieves the questions that have been manually removed, not including their 
    // child questions
    let deletedQuestions = allResponseQuestions.filter(q => {
      return Object.keys(template.questions).indexOf(q.id.toString()) === -1
    })
    // Now find all the child questions of the deleted questions and add them in the same
    // format that template.sections's questions are so that they can be added in a 
    // special 'deleted' section so they still show up when viewing the response
    for(let i = 0; i < allResponseQuestions.length; i++){
      const question = allResponseQuestions[i]
      const parentId = !question.hierarchy ? undefined : question.hierarchy.split('.').slice(-2,-1)[0]
      const parent = allResponseQuestions.find(q => q.id.toString() === parentId)
      if(parent){
        if(!parent.children){
          parent.children = []
        }
        parent.children.push(question)
        // Now that the deleted answer has been added as a child to a deleted answer parent,
        // remove the child question from the top level of the deletedQuestions array:
        const toRemoveIndex = deletedQuestions.indexOf(question)
        if(toRemoveIndex !== -1){
          deletedQuestions.splice(toRemoveIndex, 1)
        }
      }
    }

    if(error){
      return <ErrorBox message={'Something went wrong!  Please try again later.'}/>
    }
    if (loading) {
      return this.loadingQuestions()
    }
    if (!questionnaire) {
      return this.noQuestionsError()
    }
    return (
      <Fragment>
        <Sections sections={[
          ...template.sections,
          // Add the questions back to the template in a special 'deleted' section that will display
          // the old answers to questions that no longer exist in the questionnaire
          {
            id: 'deleted',
            name: "Removed Questions",
            show_legend: false,
            label: "Removed Questions",
            questions: deletedQuestions,
            // Display last in order
            order: Infinity
          }]} response={response} />
        {this.comments()}
      </Fragment>
    )
  }

  render () {
    const { loading, response, error } = this.state
    if (error && error.status === 403) {
      return <Redirect to='/unauthorized' />
    }
    if (loading) {
      return this.loadingResponse()
    }
    if (!response) {
      return this.noResponseError()
    }
    return (
      <div className='container container-response'>
        {this.actions()}
        <ResponseInfo response={response} />
        {this.details()}
        {this.actions()}
      </div>
    )
  }
}

Response.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
}

function mapStateToProps (state) {
  const { user } = state
  return {
    user
  }
}

export default connect(mapStateToProps)(Response)
