import React, {useEffect, useState} from 'react'
import * as Sentry from '@sentry/browser'
import Cookies from 'js-cookie'

import { cog } from '../api/cognition'

const cookieHideVersionWarning = 'hideVersionWarning'

const version = SWO_FE_VERSION
export default function VersionWarning () {
  const [isVersionMismatched, setIsVersionMismatched] = useState(false)
  const [shouldHide, setShouldHide] = useState(Cookies.get(cookieHideVersionWarning) || false)
  // Invokes this useEffect's callback once on startup and, to protect against long-lived pages not seeing updates,
  // every time the date changes
  const date = new Date().getDate()
  useEffect(() => {
    cog.getServerInfo()
      .then(serverInfo => {
        const versionMessage = `cognition ${serverInfo.version} | safety-observation ${version}`
        console.log(versionMessage)
        // Log version info to console for convenient reference
        const cognitionVersion = getVersionDetails(serverInfo.version)
        const swoVersion = getVersionDetails(version)
        // Add version information to sentry so that
        // all sentry-caught errors have the version
        // attached
        Sentry.configureScope((scope) => {
          scope.setExtra('version-cognition', serverInfo.version)
          scope.setExtra('version-safety-observation', version)
        })
        const mismatched = cognitionVersion.major !== swoVersion.major || cognitionVersion.minor !== swoVersion.minor
        setIsVersionMismatched(mismatched)
        if (mismatched) {
          console.error('Backend and frontend Major or Minor versions do not match.  This client may have a cached version of the frontend.\n\n', versionMessage)
          // Report a version mismatch to sentry
          // if a version mismatch has occurred, it is likely due to the client
          // caching an old version of the frontend code.
          // See cognition's readme for info on the caching strategy
          Sentry.withScope((scope) => {
            scope.setExtra('dte-version', versionMessage)
            scope.setTag('version-mismatch', mismatched)
            Sentry.captureMessage(versionMessage)
          })
        }
      })
      .catch(err => console.error('Unable to retrieve server info: ', err))
  }, [date])
  return (isVersionMismatched && !shouldHide)
    ? <div style={{
      width: '100%',
      position: 'relative',
      top:'60px',
      zIndex: 1,
      backgroundColor: '#d9534f',
      // Leave space for the 'x' button so the text doesn't cover it
      padding: '10px 40px 10px 20px',
      color: 'white'

    }}
    >
      <i className='fa fa-sync-alt' style={{padding:'10px', cursor:'pointer'}} onClick={() => {location.reload()}}/>
      &nbsp;
      This page has been updated, please <span style={{fontWeight: 'bold'}}>refresh your browser
      </span>
      <i
        style={{
          position: 'absolute',
          top: '0',
          right: '0',
          padding: '20px',
          cursor: 'pointer'
        }}
        onClick={() => {
          Cookies.set(cookieHideVersionWarning, true, {expires:1, sameSite: 'Strict'})
          setShouldHide(true)
        }}
        className={'fa fa-times'} />
    </div>
    : null
}

// Extracts the major version number and minor version number from a version string
function getVersionDetails (versionString) {
  if (typeof versionString === 'string') {
    const match = versionString.match(/(\d*).(\d*).(\d*)/)
    return {
      major: match[1],
      minor: match[2]

    }
  }
}
