import { createStore, applyMiddleware } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

import rootReducer from './reducers/index.js'

const configureStore = preloadedState => {
  if (DP_NODE_ENV === 'development') {
    return createStore(
      rootReducer,
      preloadedState,
      composeWithDevTools(
        applyMiddleware(
          thunkMiddleware
        )
      )
    )
  }
  return createStore(
    rootReducer,
    preloadedState,
    applyMiddleware(
      thunkMiddleware
    )
  )
}
export default configureStore() 
