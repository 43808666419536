/*
JDateRange are designed to act as both controlled or uncontrolled components.
They also support a 'name' attribute so that they can be used with regular HTML form actions if desired.
Optionally, use the 'required' attribute to make the browser enforce that the field is not blank.

This is an internal use component, use DateRange.jsx directly
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'
import _get from 'lodash.get'

import ErrorMessage from './ErrorMessage.jsx'
import {ISO_DATE_FORMAT} from '../constants.js'
import JDatePickerInternal from './JDatePickerInternal.jsx'

/*
The visual design of this component is
inspired by Dato
by Sindre Sorhus
*/
export class JDateRange extends Component{
  constructor(props){
    super(props)
    const newStartDate = _get(this.props, 'value.startDate')
    const newEndDate = _get(this.props, 'value.endDate')
    const formattedDefaultValueStartDate = newStartDate ? moment(newStartDate).format(ISO_DATE_FORMAT) : undefined
    const formattedDefaultValueEndDate = newEndDate ? moment(newEndDate).format(ISO_DATE_FORMAT) : undefined
    this.state = {
      hoverStartDate: formattedDefaultValueStartDate,
      hoverEndDate: formattedDefaultValueEndDate
    }
    this.hoverStartDateChange = this.hoverStartDateChange.bind(this)
    this.hoverEndDateChange = this.hoverEndDateChange.bind(this)
  }
  componentDidUpdate(prevProps){
      const {
        value = {},
        onError
      } =  this.props
      const {startDate, endDate} = value
    const prevStartDate = _get(prevProps, 'value.startDate')
    if(prevStartDate !== startDate){
      // When default startDate changes, update startDate
      // Only format if value exists
      if(startDate){
        const formattedDefaultValueStartDate = moment(startDate).format(ISO_DATE_FORMAT)
        this.setState({
          hoverStartDate: formattedDefaultValueStartDate
        })
      }
    }
    const prevEndDate = _get(prevProps, 'value.endDate')
    if(prevEndDate !== endDate){
      // When default endDate changes, update endDate
      // Only format if value exists
      if(endDate){
        const formattedDefaultValueEndDate = moment(endDate).format(ISO_DATE_FORMAT)
        this.setState({
          hoverEndDate: formattedDefaultValueEndDate
        })
      }
    }
    if(prevStartDate !== startDate || prevEndDate !== endDate){
      const error = (startDate === undefined || endDate === undefined)
        ? undefined
        : moment(startDate).isAfter(moment(endDate))
          ? 'Start Date must occur before End Date'
          : undefined
      this.setState({
        error
      })
      onError && onError(error)
    }
  }
  calculateStartDate(dateISO) {
    return dateISO === null ? null
      : this.props.isIsoWeek
        // Enforce choosing only dates at the start of an isoWeek
        ? moment(dateISO).startOf('isoWeek').format(ISO_DATE_FORMAT)
        : dateISO
  }
  calculateEndDate(dateISO) {
    return dateISO === null ? null
      : this.props.isIsoWeek
        // Enforce choosing only dates at the start of an isoWeek
        ? moment(dateISO).endOf('isoWeek').format(ISO_DATE_FORMAT)
        : dateISO
  }
  hoverStartDateChange(dateISO){
    this.setState({
      hoverStartDate:dateISO
    })
  }
  hoverEndDateChange(dateISO){
    this.setState({
      hoverEndDate:dateISO
    })
  }
  render(){
    const {
      label = 'Date Range',
      onChange,
      startDatePlaceholderText,
      endDatePlaceholderText,
      value = {}
    } = this.props
    const {
      hoverStartDate,
      hoverEndDate
    } = this.state
    const {
      startDate,
      endDate
    } = value

    const hoverHighlightStart = this.calculateStartDate(hoverStartDate)
    const hoverHighlightEnd = this.calculateEndDate(hoverEndDate)
    
    // Allow internal state to show an error and also props
    // Props can show an error in cases such as form validation
    const error = this.state.error || this.props.error

    return <div className='row'>
      {(label || error) && (
        <div className='col-xs-12' data-cy='error-message'><label>{label}</label><ErrorMessage message={error}></ErrorMessage></div>
      )}
      <JDatePickerInternal
        {...this.props}
        value={startDate}
        onChange={date => {
          const adjustedStartDate = this.calculateStartDate(date)
          typeof onChange === 'function' && onChange({ startDate: adjustedStartDate, endDate })
        }}
        onHoverDate={this.hoverStartDateChange}
        selectionHighlightStart={startDate}
        selectionHighlightEnd={endDate}
        hoverHighlightStart={hoverHighlightStart}
        hoverHighlightEnd={hoverHighlightEnd}
        className={classNames('col-xs-6', {'has-error':error})}
        placeholderText={startDatePlaceholderText || 'Start Date'}
        cy='startDate'
      />
      <JDatePickerInternal
        {...this.props}
        value={endDate}
        onChange={date => {
          const adjustedEndDate = this.calculateEndDate(date)
          typeof onChange === 'function' && onChange({ startDate, endDate: adjustedEndDate })
        }}
        onHoverDate={this.hoverEndDateChange}
        selectionHighlightStart={startDate}
        selectionHighlightEnd={endDate}
        hoverHighlightStart={hoverHighlightStart}
        hoverHighlightEnd={hoverHighlightEnd}
        className={classNames('col-xs-6', {'has-error':error})}
        placeholderText={endDatePlaceholderText || 'End Date'}
        cy='endDate'
      />
    </div>
  }
}
JDateRange.propTypes = {
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func,
  clearable: PropTypes.bool,
  value: PropTypes.shape({
    startDate:PropTypes.string,
    endDate:PropTypes.string
  })
}