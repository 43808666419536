import React, { Component } from 'react'
import PropTypes from 'prop-types'

import '../../css/filterable-table.css'

import Pagination from '../../components/Pagination.jsx'
import SortIcons from '../../components/SortIcons.jsx'
import ErrorMessage from '../../components/ErrorMessage.jsx'

const NUM_ITEMS_PER_PAGE = 10

class UserAccessTable extends Component {
  constructor (props) {
    super(props)
    this.goToPage = this.goToPage.bind(this)
    this.organizeRows = this.organizeRows.bind(this)
    this.setFilters = this.setFilters.bind(this)
    this.setSort = this.setSort.bind(this)
    this.state = {
      pageNum: 1,
      requestedFilter: null,
      authorizedFilter: null,
      sortType: null,
      sortColumn: null
    }
  }

  organizeRows (userAccess) {
    const { user } = this.props
    if(userAccess){
      if (userAccess.loading) {
        return (
          <tr>
            <td className='text-center' colSpan={4}>
              <i className='fa fa-circle-notch fa-spin fa-2x fa-fw' />
              <span className='sr-only'>Loading...</span>
            </td>
          </tr>
        )
      } else if (userAccess.data && userAccess.data.results.length > 0) {
        return userAccess.data.results.map(item => {
          const authedHierarchy = item.userAuthorizedAccessTo && item.userAuthorizedAccessTo.hierarchy
          const authorizedOrg = (authedHierarchy && authedHierarchy.organization) ? authedHierarchy.organization.name : 'Unknown'
          return (
            <tr key={item.id}>
              { user.admin && <td>{item.userAuthorizedAccessTo.display_name}</td> }
              <td>{item.userRequestedAccess.display_name}</td>
              <td>{authorizedOrg}</td>
              <td>
                <button type='button' onClick={() => this.delete(item)} className='btn btn-danger'>Delete</button>
              </td>
            </tr>
          )
        })
      } else if (userAccess.error){
        return (
          <tr>
            <td colSpan={4} className='text-center'>
              <ErrorMessage message={'Could not retrieve personnel access data, please try again later.'}/>
            </td>
          </tr>
        )
      }
    }
    return (
      <tr>
        <td colSpan={4} className='text-center'>
          <i>No Assigned Personnel Access</i>
        </td>
      </tr>
    )
  }

  goToPage (page) {
    this.setState({
      pageNum: page
    }, function () {
      this.updateTable()
    })
  }

  delete (item) {
    this.props.deleteUser(item)
    this.setState({
      pageNum: 1
    })
  }

  setFilters (value) {
    if (value.target.name === 'requested-filter') {
      this.setState({
        pageNum: 1,
        requestedFilter: value.target.value
      }, function () {
        this.updateTable()
      })
    } else {
      this.setState({
        pageNum: 1,
        authorizedFilter: value.target.value
      }, function () {
        this.updateTable()
      })
    }
  }

  updateTable () {
    const {
      pageNum,
      requestedFilter,
      authorizedFilter,
      sortType,
      sortColumn } = this.state

    this.props.change({
      pageNum: pageNum,
      numItemsPerPage: NUM_ITEMS_PER_PAGE,
      requestedFilter: requestedFilter,
      authorizedFilter: authorizedFilter,
      sortType: sortType,
      sortColumn: sortColumn
    })
  }

  setSort (column, type) {
    this.setState({
      sortType: type,
      sortColumn: column
    }, function () {
      this.updateTable()
    })
  }

  render () {
    const { userAccess, user } = this.props
    const { pageNum, sortType, sortColumn } = this.state
    const rows = this.organizeRows(userAccess)
    return (
      <div className='col-xs-12'>
        <div className='user-panel panel panel-default table-responsive filterable-table'>
          <table className='user-access-table table table-sm'>
            <thead>
              <tr>
                { user.admin &&
                <th>
                  <div>
                    Granting Access
                    <SortIcons name='authorized' sortType={sortType} sortColumn={sortColumn} setSort={this.setSort} />
                  </div>
                  <input name='authorized-filter'
                    className='col-xs-12'
                    onKeyUp={this.setFilters}
                    placeholder='Start typing name....'
                    type='text' />
                </th>
                }
                <th>
                  <div>
                      Receiving Access
                    <SortIcons name='requested' sortType={sortType} sortColumn={sortColumn} setSort={this.setSort} />
                  </div>
                  <input name='requested-filter'
                    className='col-xs-12'
                    onKeyUp={this.setFilters}
                    placeholder='Start typing name....'
                    type='text' />
                </th>
                <th>Department (SAP Organizational Unit)</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {rows}
            </tbody>
          </table>
        </div>
        { userAccess.data && (userAccess.data.total > NUM_ITEMS_PER_PAGE)
          ? <div className='row'>
            <div className='col-xs-12'>
              <div className='pull-right'>
                <Pagination
                  numberOfRows={userAccess.data.total}
                  rowsPerPage={NUM_ITEMS_PER_PAGE}
                  currentPage={pageNum}
                  selectPage={this.goToPage}
                />
              </div>
            </div>
          </div>
          : null }
      </div>
    )
  }
}

UserAccessTable.propTypes = {
  userAccess: PropTypes.object.isRequired,
  deleteUser: PropTypes.func,
  user: PropTypes.object.isRequired,
  change: PropTypes.func
}

export default UserAccessTable
