import React from 'react'
import PropTypes from 'prop-types'
import ErrorBox from './ErrorBox.jsx'

export default function ErrorOverlay (props) {
  return props.isVisible ? (
    <div className='overlay-background' style={{
      position: props.isFixedPosition ? 'fixed' : 'absolute',
    }}>
        <ErrorBox floating message={props.message} style={{ bottom: 0, top: '120px', maxWidth: '600px' }} />
    </div>) : null
}

ErrorOverlay.propTypes = {
  isVisible: PropTypes.bool,
  // Allows for moving the top position of the loading spinner,
  // useful for very tall components where the center of the component might be
  // out of view
  positionTop: PropTypes.string,
  // Allows the spinner to be always centered when the loading overlay is so tall
  // that it covers more than the whole screen.  Choosing this option is up to the developer's
  // discretion.  See EditSQS for an example of when it should be fixed
  isFixedPosition: PropTypes.bool,
  message: PropTypes.any
}
