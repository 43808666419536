import React, { Component } from 'react'
import { cog } from '../api/cognition'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import classNames from 'classnames'
import { toast } from 'react-toastify'
import LoadingOverlay from '../components/LoadingOverlay.jsx'


class HelpDocs extends Component {
  constructor (props) {
    super(props)
    this.state = {
      docs: [],
      loading: false
    }
    this.renderDoc = this.renderDoc.bind(this)
    this.getData = this.getData.bind(this)
    this.update = this.update.bind(this)
  }
  componentDidMount () {
    this.getData()
  }
  getData () {
    this.setState({ loading: true })
    cog.get('/helpDocs').then(docs => {
      if (this.props.user.admin) {
        this.setState({ docs, loading: false })
      } else {
        // Hide inactive
        this.setState({ docs: docs.filter(d => d.active), loading: false })
      }
    }).catch(() => {
      toast.error(`Something went wrong!  Please try again later`)
    }).then(() => {
      this.setState({loading:false})
    })
  }
  update (doc) {
    this.setState({ loading: true })
    cog.post('/helpDocs/update', { doc })
      .then(this.getData)
      .catch(() => {
        toast.error(`Something went wrong! Could not update document.  Please try again later`)
      })
      .then(() => {
        this.setState({loading:false})
      })
  }
  renderDoc (doc) {
    const { id, name, url, active } = doc
    return <tr key={id}>
      <td>
        <a href={url} download>
          {name}
        </a>
      </td>
      {this.props.user.admin &&
        <td>
          <div onClick={() => {
            const newName = window.prompt('What would you like to change the name to?', name)
            if(newName){
              this.update({ id, name: newName })
            }
          }} className={'btn btn-primary'}>
            Rename
          </div>
          <div onClick={() => { this.update({ id, active: !active }) }} className={classNames({
            btn: true,
            'margin-left-5': true,
            'btn-danger': active,
            'btn-primary': !active
          })}>
            {active ? 'Deactivate' : 'Activate'}
          </div>
        </td>
      }
    </tr>
  }
  render () {
    return (
      <div className='container '>
        <div className={'panel panel-default'} style={{maxWidth:'600px', margin:'20px auto 0px auto'}}>
          <div className={'panel-body'}>
              If you are encountering a problem, you can <a href='https://dteenergyprod.service-now.com/sp'>report an issue</a> with the IT Help Desk.
          </div>
        </div>
        <div className='page-header'>
          <h3>Help Documents</h3>
          <p>
            Click on the name of a document to view or download the corresponding PDF
          </p>
        </div>
        <div className='row'>

          <div className='col-xs-12'>
            <LoadingOverlay isVisible={this.state.loading} />
            <div className='panel panel-default table-responsive'>
              <table className='table'>
                <thead>
                  <tr>
                    <th>Document</th>
                    {this.props.user.admin &&
                      <th>Actions</th>
                    }
                  </tr>
                </thead>
                <tbody>
                  {this.state.docs.map(this.renderDoc)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

HelpDocs.propTypes = {
  dispatch: PropTypes.func,
  user: PropTypes.object
}

function mapStateToProps (state) {
  const { user } = state
  return {
    user
  }
}

export default connect(mapStateToProps)(HelpDocs)
