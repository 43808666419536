import React, {useState, useRef, useEffect} from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import className from 'classnames'

const tooltipPortal = document.getElementById('tooltip-portal')
export default function WithTooltip(props){
  const {text, children} = props
  const [visible, setVisible] = useState(false)
  const [pos, setPos] = useState({})
  const holderRef = useRef(null)

  // Don't render the tooltip if there is no text, but 
  // ALWAYS render the children of WithTooltip.
  // This supports the possibility that elements will be wrapped
  // in WithTooltip but will only conditionally use a tooltip.
  // In that case, only the tooltip behavior should be hidden,
  // not the child elements.
  const tooltip = !text ? null : ReactDOM.createPortal(
    <div className='tooltip top' role="tooltip" style={{
      display: visible ? 'block' : 'none',
      top:pos.top,
      left:pos.left,
    }}>
      <div className={className("tooltip-content fade top in")} style={{
        bottom:'13px',
        position:'absolute',
        minWidth:'300px'
      }}>
        {/* <div className="tooltip-arrow" style={{left: '50%', bottom:'-5px'}}></div> */}
        <div className="tooltip-inner">{text}</div>
      </div>
    </div>,
    tooltipPortal
  )
  useEffect(_ => {
    const handleScroll = _ => { 
      // Hide tooltip when scrolling
      // This is useful in the case that the user hovers over the child element, showing the tooltip
      // then scrolls away without moving the mouse.  Without this logic, the tooltip would remain until
      // the mouse was moved again.
      setVisible(false)
    }
    window.addEventListener('scroll', handleScroll)
    return _ => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])
  return <div
    ref={holderRef}
    onMouseEnter={() => {
      const firstChild = holderRef.current.children[0]
     if(firstChild){
       // Capture the bounding rect of the first child.
       // The tooltip should display relative to the child
       // because the child could be block or inline-block
       // and the tooltip would not display accurately if
       //
      const rect = firstChild.getBoundingClientRect()
      setPos({
        top:`${rect.top}px`,
        left:`${rect.left}px`,
        right:`${rect.right}px`,
        bottom:`${rect.bottom}px`,
        width:`${rect.width}px`,
        height:`${rect.height}px`
      })

     } 
      setVisible(true)
    }} 
    onMouseLeave={() => {
      setVisible(false)
    }}
  >
    {children}
    {tooltip}
    {/* Debug box */}
    {/* {ReactDOM.createPortal(
      <div style={{
        top:pos.top,
        left:pos.left,
        right:pos.right,
        bottom:pos.bottom,
        width:pos.width,
        height:pos.height,
        position:'absolute',
        border:'1px solid red'
      }}/>, tooltipPortal
    )} */}
  </div>
}
WithTooltip.propTypes = {
  text: PropTypes.string,
  position: PropTypes.string
}