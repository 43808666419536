import React from 'react'
import PropTypes from 'prop-types'

// A loading spinner with optional percentage
export default function Spinner (props) {
  const {percentage} = props
  return <span>
    <i className='fa fa-circle-notch fa-spin' />
    {/*
    Hide percentage on both undefined and 0.
    It is common for requests to spend some time on 0% because multiple
    requests have been made, but none have returned yet.  For the sake
    of UX, don't show 0% which could lead the user to believe that it's stuck,
    only show percentage once it's > 0
      */}
    {percentage > 0 ? `  ${Math.floor(percentage)}%` : ''}
  </span>
}

Spinner.propTypes = {
  // 0 - 100
  percentage: PropTypes.number
}
