import React, { Component, useEffect, useState } from 'react'
import { cog } from '../api/cognition'
import Spinner from '../components/Spinner.jsx'


const SERVICE_UNAVAILABLE = 'Service Unavailable'
const UP = 'Operational'
const DOWN = 'Down'
const PENDING = 'Pending'
const UNKNOWN = 'Unknown'
const UNAUTHORIZED = 'Unauthorized'

class Status extends Component {
  constructor(props) {
    super(props)
    this.state = {
      cognition: { msg: PENDING },
      database: { msg: PENDING },
      databaseReplica: { msg: PENDING },
      msGraph: { msg: PENDING }
    }
  }
  componentDidMount() {
    cog.getDBStatus().then(() => {
      this.setState({ database: { ok: true } })
    }).catch(err => {
      switch (err.status) {
        case 503:
          this.setState({ database: { ok: false, msg: SERVICE_UNAVAILABLE } })
          break
        default:
          this.setState({ database: { ok: false, msg: DOWN } })
      }
    })
    cog.get('/status/db_replica').then(() => {
      this.setState({ databaseReplica: { ok: true } })
    }).catch(err => {
      switch (err.status) {
        case 503:
          this.setState({ databaseReplica: { ok: false, msg: SERVICE_UNAVAILABLE } })
          break
        default:
          this.setState({ databaseReplica: { ok: false, msg: DOWN } })
      }
    })
    cog.getServerInfo().then(() => {
      this.setState({ cognition: { ok: true } })
    }).catch(() => {
      this.setState({
        cognition: { ok: false },
        // Database health is queried through cognition, so if cognition is down, we cannot know the state of the db from here
        database: { ok: null, msg: UNKNOWN },
        databaseReplica: { ok: null, msg: UNKNOWN }
      })
    })
    cog.get('/status/msGraph').then(() => {
      this.setState({ msGraph: { ok: true } })
    }).catch(err => {
      switch (err.status) {
        case 503:
          this.setState({ msGraph: { ok: false, msg: SERVICE_UNAVAILABLE } })
          break
        default:
          this.setState({ msGraph: { ok: false, msg: DOWN } })
      }
    })
  }
  render() {
    return (
      <div className='page-wrapper'>
        <div className='container'>
          <div className='page-header'>
            <h3 className='text-center'>Service Status</h3>
          </div>
          <div className='panel panel-default'>
            <div className='panel-body'>
              {Object.keys(this.state).map(key => SingleStatus(key, this.state[key]))}
            </div>
          </div>
          <EndpointAvailability />
          <Polyfills />
        </div>
      </div>
    )
  }
}

const colorPending = '#fb7f00'
function colorFromStatus({ ok, msg }) {
  return ok ? 'green' : msg === UNKNOWN || msg === PENDING ? colorPending : 'red'
}
function SingleStatus(name, { ok, msg }) {
  const color = colorFromStatus({ ok, msg })
  return (<div
    key={name}
    style={{
      height: '40px',
      padding: '8px',
      margin: '8px',
      border: `1px solid ${color}`,
      display: 'flex',
      justifyContent: 'space-between'
    }}>
    <div style={{ textTransform: 'capitalize' }}>{name}</div>
    <div style={{ color }}>{msg || (ok ? UP : DOWN)}</div>
  </div>)
}

const endpoints = [
  '/categories',
  '/companies',
  '/me/elevated_expectation',
  '/exclusions',
  '/foreignKeyCache',
  '/global_message',
  '/helpDocs',
  '/inclusions',
  '/leaderExclusion',
  '/locations',
  '/businessUnits',
  '/organizations',
  '/responses?id=1',
  '/sections',
  '/special-user',
  '/users/me',
  '/users/user-access'
]
class Endpoint extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: null
    }
  }
  componentDidMount() {
    const { path, onResult } = this.props
    cog.get(path).then(a => {
      console.log('Endpoint:', path, a)
      this.setState({ status: UP })
      onResult(UP)
    }).catch(e => {
      console.log('Error', path, e)
      if (e.status === 403 || e.status === 401) {
        this.setState({ status: UNAUTHORIZED })
        onResult(UNAUTHORIZED)
      } else {
        this.setState({ status: DOWN })
        onResult(DOWN)
      }
    })

  }
  render() {
    const { path } = this.props
    const { status } = this.state
    const color = status === UP ? 'green' : status === DOWN ? 'red' : colorPending
    return <tr>
      <td>{path}</td>
      <td style={{ color }}>{status}</td>
    </tr>
  }
}
function EndpointAvailability() {
  const [numberSucceeded, setNumberSucceeded] = useState(0)
  const [numberFailed, setNumberFailed] = useState(0)
  const [numberUnauth, setNumberUnauth] = useState(0)
  const [asyncEndpoints, setAsyncEndpoints] = useState([])
  const [begun, setBegun] = useState(false)
  const totalRequests = asyncEndpoints.length + endpoints.length
  const numberPending = (totalRequests) - (numberFailed + numberSucceeded + numberUnauth)
  const renderEndpoint = (path) => {
    return <Endpoint key={path} path={path} onResult={status => {
      if (status === UP) {
        setNumberSucceeded(prev => prev + 1)
      }
      if (status === DOWN) {
        setNumberFailed(prev => prev + 1)
      }
      if (status === UNAUTHORIZED) {
        setNumberUnauth(prev => prev + 1)
      }
    }} />
  }
  const endpointBody = begun ?
    (
      <div className='col-md-12'>
        <div className='panel panel-default'>
          <div className='panel-body'>
            {!!numberPending && <div style={{ color: colorPending }}><Spinner /> {numberPending} / {totalRequests} requests pending</div>}
            {!!numberUnauth && <div style={{ color: colorPending }}>{numberUnauth} / {totalRequests} requests unauthorized for this user</div>}
            {!!numberSucceeded && <div style={{ color: 'green' }}>{numberSucceeded} / {totalRequests} requests succeeded</div>}
            {!!numberFailed && <div style={{ color: 'red' }}>{numberFailed} / {totalRequests} requests failed</div>}
          </div>
        </div>
        <div className='panel panel-default'>
          <table className='table table-sm table-striped'>
            <thead>
              <tr>
                <th>Path</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {endpoints.map(renderEndpoint)}
              {asyncEndpoints.map(renderEndpoint)}
            </tbody>
          </table>
        </div>
      </div>

    ) : (
      <div>
        <button className='btn btn-primary' onClick={() => {
          cog.get('/questionnaires').then(qs => {
            qs.forEach(({ slug }) => {
              setAsyncEndpoints(prev => [`/questionnaires/${slug}`, ...prev])
            })
          })
          setBegun(true)
        }}>Get Endpoint Status (requires login)</button>
      </div>

    )
  return <div>
    <div className='col-md-12'>
      <div className='page-header'>
        <h3 className='text-center'>Endpoint Availability</h3>
      </div>
    </div>
    {endpointBody}
  </div>
}

// Polyfills //
function tryCallback(cb, expected) {
  return new Promise((res, rej) => {
    try {
      const actual = cb()
      if (JSON.stringify(actual) === JSON.stringify(expected)) {
        res()
      } else {
        rej(new Error('Did not throw but expected value did not match'))
      }
    } catch (e) {
      console.error(e)
      rej(e)
    }
  })
}
const polyfillTests = [
  {
    name: 'Object.values',
    cb:
      () => {
        const obj = { a: 1, b: 2 }
        return Object.values(obj)
      },
    expected: [1, 2]
  },
  {
    name: 'Object.assign',
    cb:
      () => {
        const obj = { a: 1 }
        return Object.assign({}, obj, { c: 3 })
      },
    expected: { a: 1, c: 3 }
  },
  {
    name: 'Array.isArray',
    cb:
      () => {
        return Array.isArray([1, 2, 3])
      },
    expected: true
  },
  {
    name: 'Spread syntax',
    cb:
      () => {
        const a = { a: 1 }
        const b = { b: 2 }
        return { ...a, ...b }
      },
    expected: { a: 1, b: 2 }
  },
  {
    name: 'String.includes',
    cb:
      () => {
        const actual = 'This is a test'.includes('is a t')
        return actual
      },
    expected: true
  }

]
function Polyfills() {
  const [succeeded, setSucceeded] = useState([])
  const [failed, setFailed] = useState([])
  useEffect(() => {
    for (let test of polyfillTests) {
      const { name, cb, expected } = test
      tryCallback(cb, expected).then(() => {
        setSucceeded(prev => prev.concat(name))
      }).catch(() => {
        setFailed(prev => prev.concat(name))
      })
    }

  }, [])
  return <div>
    <div className='col-md-12'>
      <div className='page-header'>
        <h3 className='text-center'>Polyfills</h3>
      </div>
    </div>
    <div className='col-md-12'>
      <div className='panel panel-default'>
        <div className='panel-body'>
          {!!succeeded.length && <div style={{ color: 'green' }}>{succeeded.length} polyfills work</div>}
          {!!failed.length && <div style={{ color: 'red' }}>{failed.length} polyfills failed</div>}
        </div>
      </div>
      <div className='panel panel-default'>
        <table className='table table-sm table-striped'>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {failed.map(name =>
              <tr key={name}>
                <td>{name}</td>
                <td style={{ color: 'red' }}>Not Available</td>
              </tr>
            )}
            {succeeded.map(name =>
              <tr key={name}>
                <td>{name}</td>
                <td style={{ color: 'green' }}>Functional</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </div>
}
export default Status
