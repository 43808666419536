import OAuth from '../auth/oauth'


export const addRequestAuthOptions = (options = {}) => {
  const token = OAuth.accessToken
  const reqOptions = Object.assign({}, options)
  if (token) {
    const authHeader = {'Authorization': `Bearer ${token}`}
    reqOptions.headers = options.headers ? Object.assign({}, options.headers, authHeader) : authHeader
  }
  return reqOptions
}
