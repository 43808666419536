import React, { Component } from 'react';
import { render, unmountComponentAtNode } from 'react-dom';
import oauth from '../auth/oauth'

export function show({ allowReanimate } = {}) {
  if (document.getElementById('button-login-main')) {
    // Do not render the AuthToast when the regular login button is present
    return
  }
  if (allowReanimate) {
    // Hide previous toast before showing a new one, this provides the effect of 
    // reanimating the toast.
    // (Useful, for example, if the user is receiving multiple 401s but didn't notice the toast the first time)
    hide()
  }
  // Render the toast in the holder element
  render(<AuthToast />, holderEl);
}

export function hide() {
  const wasMountedNowUnmounted = unmountComponentAtNode(holderEl)
  if(wasMountedNowUnmounted){
    console.debug('AuthToast: hide toast')
  }
}

class AuthToast extends Component {
  render() {
    return <div style={{
      position: 'fixed',
      margin: '8px auto',
      bottom: '0px',
      right: 0,
      left: 0,
      width: '350px',
      maxWidth: '100%',
      zIndex: 10000
    }}>
      <div style={{
        margin: '0 8px',
        padding: '16px',
        borderWidth: '4px',
      }} className='authToast panel panel-default'>
        <div style={{ textAlign: 'center', marginBottom:'4px' }}>Session expired.  Please sign back in. </div>
        <button
          type='button'
          className='authToastLink btn btn-primary'
          style={{width:'100%'}}
          onClick={() => {
            oauth.login()
          }}
          aria-label="Session expired, sign back in"
        >
          Sign In
        </button>
      </div>
    </div>
  }
}

// One-time setup
const holderEl = document.createElement('div');
holderEl.className = 'auth-toast-holder'
document.body.appendChild(holderEl);