// Browser check variables from msal-browser-samples/VanillaJSTestApp2.0/app/default/auth.js
// If you support IE, our recommendation is that you sign-in using Redirect APIs
// If you as a developer are testing using Edge InPrivate mode, please add "isEdge" to the if check
const ua = window.navigator.userAgent;
const msie = ua.indexOf("MSIE ");
const msie11 = ua.indexOf("Trident/");
// const msedge = ua.indexOf("Edge/");
export const isIE = msie > -1 || msie11 > -1;
// const isEdge = msedge > -1;

// Note: it is usually not advisable to use the userAgent for browser checking; however in this instance, this is how
// the Microsoft MSAL authentication library toggles on support for IE