import React, { Component } from 'react'
import ReactGA from 'react-ga'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import OAuth from '../auth/oauth.js'

import '../css/auth.css'

class InvalidDomain extends Component {
  render () {
    const { user } = this.props

    ReactGA.set({ page: window.location.pathname })
    ReactGA.pageview(window.location.pathname + window.location.search)

    return (
      <div className='page-wrapper'>
        <div className='container container-login'>
          <div className='jumbotron jumbotron-restricted text-center'>
            <h2>
              Oops, wrong account!
            </h2>
            <p>
              <strong><em>{user.profile.email}</em></strong> is not the correct login for this application.
            </p>
            <p>
              Please <u>log out</u>, then log in using a <strong><em>@dteenergy.com</em></strong> email address.
            </p>
            <p>
              <button onClick={() => OAuth.logout()} className='btn btn-primary btn-lg btn-block'>
                <i className='fa fa-sign-in' aria-hidden='true' /> Log out
              </button>
            </p>
          </div>
        </div>
      </div>
    )
  }
}

InvalidDomain.propTypes = {
  user: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    user: state.user
  }
}

export default connect(mapStateToProps)(InvalidDomain)
