import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

export default function ButtonGroup(props) {
  const { buttons, defaultValue, label, value: _value, onChange, className, allowMultiple = false } = props
  /* 
    Note: Should not pass both defaultValue and value as props. defaultValue is to
    be used only when it is an uncontrolled component.  To set a default value for ButtonGroup as a
    controlled component, simply initially set value as whatever the default value should be.
  */
  const [activeKey, setActiveKey] = useState(typeof defaultValue !== 'undefined'
    ? defaultValue
    : null)
  // ButtonGroup can act as a controlled component (pass in value and onChange to props) or it can hold it's own
  // internal state.
  // If _value and activeKey are undefined or null, default to [] (if allowMultiple) or null (if !allowMultiple)
  const value = (typeof _value !== 'undefined' && _value !== null)
    ? _value
    : (typeof activeKey !== 'undefined' && activeKey !== null)
      ? activeKey
      : (allowMultiple ? [] : null)
  if (allowMultiple && value && typeof value.length === 'undefined') {
    console.error('ButtonGroup: If allowMultiple, value should always be an array.')
  }
  const labelElement = label ? <div style={{ fontWeight: '700' }}>{label}</div> : null
  return (
    <div>
      {labelElement}
      <div className={classNames('btn-group', className)} aria-label={label} role='group'>
        {buttons.map(b => {
          const key = b.key !== undefined ? b.key : b.name
          const isActive = allowMultiple ? (value && value.indexOf(key) !== -1) : key === value
          return <button type='button' key={key} className={classNames('btn', {
            'btn-default': !isActive,
            'btn-primary': isActive
          })} onClick={() => {
            let newlySelected = key
            if (allowMultiple) {
              const isKeyAlreadyActive = value && value.indexOf(key) !== -1
              if (isKeyAlreadyActive) {
                // Then deactivate but keep the rest of the active
                newlySelected = value.filter(ak => ak !== key)
              } else {
                newlySelected = [...value, key]
              }
            }
            setActiveKey(newlySelected)
            if (b.onChange) {
              // If the button overrides the onChange
              b.onChange(newlySelected)
            } else if (onChange) {
              // Group onChange
              onChange(newlySelected)
            }
          }}>{b.name}</button>
        }
        )}
      </div>
    </div>
  )
}
ButtonGroup.propTypes = {
  buttons: PropTypes.array.isRequired,
  defaultValue: PropTypes.any,
  onChange: PropTypes.func,
  className: PropTypes.string,
  label: PropTypes.string,
}
