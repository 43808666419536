import React from 'react'
import PropTypes from 'prop-types'
import '../css/modal.css'

class Modal extends React.Component {
  constructor (props) {
    super(props)
    this.cancelAction = this.cancelAction.bind(this)
    this.affirmAction = this.affirmAction.bind(this)
  }

  cancelAction () {
    this.props.callbackFromModal('cancel')
  }

  affirmAction (callbackKey) {
    this.props.callbackFromModal(callbackKey)
  }

  render () {
    const { bodyText, headerText, footerText, callbackKey } = this.props
    return (
      <div className='container container-modal'>
        <div className='modal fade in' id='myModal' tabIndex='-1' role='dialog'>
          <div className='modal-dialog' role='document'>
            <div className='modal-content'>
              <div className='modal-header'>
                {headerText && <strong> {headerText} </strong>}
                <button type='button' className='close' onClick={this.cancelAction} data-dismiss='modal' aria-label='Close'><span aria-hidden='true'>&times;</span></button>
              </div>
              <div className='modal-body'>
                {bodyText}
              </div>
              <div className='modal-footer'>
                <button type='button' className='no-btn btn-default btn' onClick={(this.cancelAction)}>{footerText.cancel}</button>
                <button type='button' className={`yes-btn btn ${footerText.affirm === 'Yes' ? 'btn-primary' : 'btn-danger'}`} onClick={() => this.affirmAction(callbackKey)}>{footerText.affirm}</button>
              </div>
            </div>
          </div>
        </div>
        <div className='modal-backdrop' />
      </div>
    )
  }
}

Modal.propTypes = {
  callbackFromModal: PropTypes.func,
  bodyText: PropTypes.any,
  headerText: PropTypes.string,
  footerText: PropTypes.object,
  callbackKey: PropTypes.string
}

export default Modal
