import React, { Fragment } from 'react'

export default function Select(props){
  const {value, onChange, options, className, labelText} = props
  return (
    <Fragment>
      {(!!labelText) && (<label>{labelText}</label>)}
      <select className={className} value={value || ''} onChange={evt => onChange(evt.target.value)}>
        {options.map(({value,label, disabled}) => <option key={`${label}-${value}`} value={value} disabled={disabled}>{label}</option>)}
      </select>
    </Fragment>
  )
}