import React from 'react'
import moment from 'moment'

const SubNavigation = () => {
  return (
    <div className='navbar-sub-dte'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-4'>
            <svg style={{
              fill:'#333',
              marginTop:'3px'
            }} data-name='DTE Logo' height='13px' viewBox='0 0 275 90' x='0px' y='0px' xmlns='http://www.w3.org/2000/svg'><path d='M323.74,121.13h-25v67.44H266.32V121.13h-25V99.49h82.46Zm85.91-.35V99.43H336.1v67.73a21.16,21.16,0,0,0,21.17,21.16H410V166.87H372.27a5,5,0,0,1-4.95-5v-8.8h40.62V133.38H367.32v-12.6ZM204.91,188.49H150V99.58h54.74a26.2,26.2,0,0,1,26.19,26.19v36.72A26,26,0,0,1,204.91,188.49Zm-4-28.11V127.31a6.82,6.82,0,0,0-6.82-6.82h-12v46.72h12A6.82,6.82,0,0,0,200.92,160.38Z' transform='translate(-149.98 -99.43)' /></svg>
            {window.location.host.indexOf('localhost') !== -1 && <span style={{color:'#ff5c79'}}> LOCAL DEV ENVIRONMENT</span>}
          </div>
          <div className='col-xs-8 text-right'>
            <span className='hidden-xs'>
              Today is <strong>{moment().format('dddd, MMMM Do, YYYY')}</strong>
            </span>
            <span className='hidden-sm hidden-lg hidden-md'>
              Today is <strong>{moment().format('MM/DD/YYYY')}</strong>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubNavigation
