import React, { PureComponent } from 'react'
import { cog } from '../api/cognition'

export default class EmployeeNameByAzureId extends PureComponent{
    constructor(props){
        super(props)
        this.state = {
            name: <i className='fa fa-circle-notch fa-spin' />
        }
    }
    componentDidMount(){
        this.fetchUser()
    }
    componentDidUpdate(){
        this.fetchUser()
    }
    fetchUser(){
        const {azure_id} = this.props
        if(azure_id){
            cog.get('/users/'+azure_id).then(user => {
                if(user){
                    this.setState({name:user.display_name})
                }else {
                    throw new Error('user not returned in EmployeeNameByAzureId')
                }
            }).catch(e => {
                console.error(e)
                this.setState({name:azure_id})
            })
        }
    }
    render(){
        return this.state.name
    }
}