import React from 'react'
import { cog } from '../../api/cognition'
import SelectAsync from './SelectAsync.jsx'



function loadOptions (input) {
  return cog.companySearch(input || '').then(data =>
    data.companies.map(company => ({
      value: company.id,
      label: company.name
    })).concat(
      [{value:undefined, label: 'Other / Not Listed'}]
    )
  )
}

function SelectCompany (props) {
  return <SelectAsync
    {...props}
    minInput={2}
    loadOptions={loadOptions}
  />
}

SelectCompany.defaultProps = {
  labelText: 'Company',
  placeholder: 'Type to search companies...'
}

export default SelectCompany
